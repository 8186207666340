:global {
  // Roboto (Normal, Semibold)
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

  html,
  body {
    font-size: 14px;
  }

  body,
  input,
  textarea {
    font-family: $font-stack;
    color: $gray-900;
  }

  h1 {
    font-size: 1.571428571rem;
    font-weight: 500;
  }

  h2 {
    font-size: 1.285714286rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.142857143rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  h5 {
    font-size: 0.857142857rem;
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    line-height: 1.571428571;
  }

  p.light {
    color: $gray-700;
  }

  a {
    color: $gray-900;
    text-decoration: none;

    @include focus;
  }

  b {
    font-weight: 500;
  }

  ul {
    padding-left: $space-m;
  }

  li {
    font-size: 1rem;
    line-height: 1.571428571;
    list-style-type: disc;
    margin-bottom: $space-xxxs;
  }

  code {
    background-color: $gray-200;
    border-radius: $roundness-m;
    border: 1px solid $gray-300;
    color: #d14b71;
    font-family: monospace;
    font-size: 0.857142857rem;
    padding: $space-xxxs;
  }
}
