@import '../../styles/variables.scss';

.deleteWrap {
  text-align: center;
  margin: $space-l 0;
}
.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.icon {
  color: $green-600;
  margin-bottom: $space-xs;
}

.rowItem {
 
}
.accordionWrapper > div {
 
}

.accordionHeader {
  width: 100%;
}

.accordionHeader > div {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.accordionHeader > div:last-child {
  float: right;
}

.accordionHeader i {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.accordionHeader div:first-child i {
  margin-right: 8px;
}

.accordionHeader div:last-child i {
  margin-left: 8px;
}

.accordionHeader div:last-child span::after {
  content: "view";
}
.spacer{
 
  display: inline-flex;
  margin-right: $space-xxxs;
}
.infoText {
  color: black;
}

.whiteText {
  height: 100%;
  color: black;
}
//

.wrap {
  align-items: center;
 // display: inline-block;
  justify-content: center;
}

.row {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}
.expired, .red{
  color: red;
 
}

.filterMenu{
  //justify-content: end!important;
  background: white!important;
  flex-direction: column;
}

.row > * {
  flex: 0.5;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
      
    }
  }
}

.expiringsoon{
  background-color: $yellow !important;
}
.popover div{

  display: block!important;

}

.rowHeader{
//color:red;
}



.row div{
  height: 23px;
  align-items: center;
  //justify-content: left;
  @media screen and (max-width: $mobile) {
    margin: 3px 0;
  }
}

.row div:nth-child(1)  {

flex-grow:2;
display: flex;
justify-content: left;
}
.row div:nth-child(2) {

  flex-grow: 1;
  justify-content: left;
  display: flex;
}
.row div:nth-child(3) {

  margin-left: 20px;
  flex-grow: 3;
  justify-content: left;
  display: flex;
  @media screen and (max-width: $mobile) {
    margin-left: unset;
  }
}
.row div:nth-child(4) {

  flex-grow: 4;
  display: flex;
  justify-content: left;
}
.eventWrapper {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}



//license
.license > div {
  width: 100%;
  padding: $space-xxs;
 
  display: flex;
  @media screen and (max-width: $mobile) {
    display: unset;
  }
  justify-content: left;
 // align-items: flex-start;
}

.license > div:nth-child(odd) {
  background:#f8f8f8;
}


.license {
  background-color: $white;
  padding: $space-xxs;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  // border: 1px $gray-700 solid;
}


.selected,
.empty {
  color: $gray-700;
  margin-bottom: $space-xs;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top: $space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}

.labelActiveDamage,
.labelActiveNone,
.labelActive {
  background-color: $white;

}

.labelActiveNone {
  border-color: $green-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.labelActive {
  border-color: $orange-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.img {
  // margin-bottom: $space-xs;
  user-select: none;
}

.img img {
  width: 58px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding: 0 $space-xxs;
  position: relative;
  // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;

  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}
.center {
  //margin-top: 45%;
}
.sub_text {
  font-size: 0.857142857rem;
  line-height: 0.971428571;
  color: #777777;
  // text-align: justify;
  padding: $space-s;
  position: relative;

  @media screen and (max-width: $mobile) {
    padding: $space-xxs;
    font-size: 0.757142857rem;
    line-height: 0.801428571;
    //height:210px;
  }


}

.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;

}
.flexContainer {

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexContainer>.flexRow {
  flex: 1 1 100%;
  /*grow | shrink | basis */
  height: auto;
}


.wizzardPickerWrapper>div:first-child {
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}

.wrap2 {
  display: flex;
  height: 100%;
  justify-content: center;
}

.wrap2 {
  display: flex;

  &>* {
    flex: 1;
  }
}