@import '../../styles/variables.scss';

.label {
 // display: block;
  padding-bottom: $space-xs;
  display: inline-flex!important;
  align-items: center;
}
.red{
  color:$red-600;
  display: inline-block;
}
.icon {
  color: $gray-700;
  float: left;
  margin-right: $space-xxs;
  position: relative;
  top: -4px;
}

.light {
  color: $gray-700;
}
.right {
  color: $gray-700;
  margin-left: auto;
  padding-left: $space-s;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.left {
  padding-right: $space-s;
}


.tooltip
{
  font-size: 1rem!important;
  padding: 8px!important;
  line-height: 1.4;
  max-width: 300px;
  margin-left: $space-s;
  @media screen and (max-width: $mobile)
  {
    max-width: 70vw;
  }
}

.tooltipItemWrapper > i
{
  margin-left: $space-xxxxs;
}

.tooltipItemWrapper
{
  display: inline-flex!important;
  align-items: center;
  color: $gray-700;
}
