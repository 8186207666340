$brand: #f26925;

$black: #000000;
$white: #ffffff;

$gray-900: #242424;
$gray-800: #4e4e4e;
$gray-700: #777777;
$gray-500: #a9a9a9;
$gray-300: #d9d9d9;
$gray-200: #eeeeee;
$gray-100: #f8f8f8;

$green-600: #05b008;

$red-800: #b52323;
$red-600: #ee0e0e;

$orange-600: #ff7b00;
$orange-300: #ffb217;
$yellow: #ffedc8;
$blue-300: #4a90e2;
