@import '../../styles/variables.scss';

.center {
  padding: $space-xxxs;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  z-index: 999991;
  width: 320px;
  left: 50%;
  margin-left: -160px;
}

.spacer {
  padding-left: 20px;
}