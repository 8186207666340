@import "../../styles/variables.scss";

.totalImpactsNumber {
  color: $gray-700;
  margin-left: auto;
  margin-right: auto;
}

.group {
  padding-bottom: $space-xxxs;
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}

.deviceWrap {
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  padding-top: $space-s;
  padding-left: $space-s;
  padding-right: $space-s;
}

// Styles go here
.holder {
  background-color: $gray-300;
  margin: $space-s;
}

.lineHeight {
  line-height: 1.571428571;
}

.labelActiveQr,
.labelActiveManual,
.labelActive {
  background-color: $white;
}

.labelActiveNone {
  border-color: $green-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.labelActive {
  border-color: $orange-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.inputWrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.icon {
  color: $green-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.error {
  color: $red-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;

  @media screen and (max-width: $mobile) {
    margin-bottom: 0;
  }
}

.donotdisplay {
  display: none;
}

.body {
  max-width: 380px;
  padding-top: $space-xs;
  text-align: center;
}

.bodyLeft {
  max-width: 380px;
  padding-top: $space-xs;
}

.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $mobile) {
    // height: 100px;
    height: unset;
  }

  & img {
    max-height: 50%;
    max-width: 50%;
  }
}

.wrapvert {
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;
}
.licence_warning {
  color: $red-600;
  font-size: 1.171428571rem;
  font-weight: 500;
  //height: 24px;
  margin-top: 10px;
}

.disabled {
  user-select: none !important;
  opacity: 0.5 !important;
  pointer-events: none;
  cursor: not-allowed !important;
}

.disabledPointer {
  cursor: not-allowed !important;
}

.detailsHead {
  .value {
    font-weight: 500;
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $space-m;
  border-bottom: 1px solid $gray-300;

  & > * {
    flex: 1;
    flex-basis: 100%;
    margin-bottom: 30px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $mobile) {
    & > * {
      flex: 0 0 100%;
    }
  }
}

.notificationsControlWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  // border-top: 1px $gray-300 solid;
}

.notificationsControlWrapper span {
  margin-bottom: 0;
}

.details {
  vertical-align: top;

  .value {
    color: $black;
  }

  display: flex;
  align-items: top;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $space-m;

  & > * {
    flex: 1;
    flex-basis: 33.3%;
    margin-bottom: 0;
  }

  @media screen and (min-width: $desktop-l) {
    & > *:last-child {
      flex-basis: 10%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    & > *:last-child {
      flex-basis: 10%;
    }
  }

  @media screen and (device-width: 1024px) {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-l;
    }

    & > *:nth-last-child(-n + 2) {
      //margin-bottom: 0!important;
    }
  }

  @media screen and (max-width: 700px),
    screen and (min-width: $desktop-s) and (max-width: $desktop-l) {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-l;
    }
  }

  @media print and (device-width: $tablet) {
    & > * {
      flex: 0 0 33.3%;
      margin-bottom: $space-l;
    }

    > *:nth-last-child(-n + 2) {
      margin-bottom: 0 !important;
    }
  }

  @media screen and (max-width: $mobile) {
    & > * {
      flex: 0 0 100%;
      margin-bottom: $space-m;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.sliderPadding {
  padding: $space-xs;
}

.mainInfo {
  padding: $space-m;
}

.delete {
  text-align: center;
}

.floatright {
  position: absolute;
  right: 5px;
  top: 5px;
}

.floatleft {
  position: absolute;
  left: 5px;
  top: 5px;
}

.wrapper {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.w30 {
  margin-left: 24px;
  margin-right: 20px;
  width: 10%;
  align-items: center;
}

.w70 {
  align-items: center;

  width: 90%;
  height: 35px;
}

.smallwrap {
  margin-right: 0;
  margin-left: 20px;
  width: 20px;
}

.break {
  height: 1px;
  width: 100%;
  background: $gray-700;
  color: $gray-700;
  margin: $space-l 0 $space-l, 0;
}

.uploadButton {
  cursor: pointer;

  position: absolute;
  right: 5px;
  top: 5px;

  & > button:focus {
    box-shadow: none;
  }

  &:active > button {
    box-shadow: none !important;
    color: $gray-700;
  }
}

.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexContainer > .flexRow {
  // flex: 1 1 100%; /*grow | shrink | basis */
  //height: auto;
}

.wrap2 {
  display: flex;
}

.wizzardPickerWrapper > div:first-child {
  display: block;
}

.labelActive {
  border-color: $orange-600 !important;
}

.input {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer !important;
  display: block;
  font-size: 100px;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.break {
  height: 1px;

  background: $gray-300;
  margin: 0 $space-xs $space-l 0;
}

.wrapModal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.div {
  text-align: center;
  margin-bottom: $space-m;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top: $space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}

.img {
  text-align: center;
  //margin-top: 20px;
}

.textDesc {
  font-size: 0.857142857rem;
  user-select: none;
  text-align: center;
}

.text {
  flex: 1;
  padding: 0 $space-m;
  text-align: left;

  @media screen and (max-width: $mobile) {
    flex-basis: 75%;
  }
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}

.subtitle {
  color: $gray-700;
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}

.textModal {
  text-align: left;
}

.editButton {
  position: absolute;
  right: 24px;
  top: 24px;
}

.admin {
  max-width: calc(100% - 90px);
}

.simpleModeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.simpleModeContainer > .value {
  font-weight: bold;
  font-size: 150%;
  text-align: center;
  max-width: unset;
}

.simpleModeContainer > button > span {
  font-size: 165%;
  place-content: center;
}

.simpleModeContainer > button i {
  font-size: 150% !important;
}

.simpleModeContainer > * {
  margin-bottom: $space-m;
}

.simpleModeContainer > button {
  min-height: 10vh;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding: 0 $space-xxs;
  position: relative;
  // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;

  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}
