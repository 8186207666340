@import '../../styles/variables.scss';

.wrap {
  border-radius: $roundness-m;
  //border: 1px solid $gray-300;
  min-height: 110px;
  position: relative;

  &--error {
    border-color: $red-800;
  }
}

.state {
  align-items: center;
  background-color: $white;
  border-radius: $roundness-m;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.uploadArea {
  align-items: center;
  display: flex;
  height: 110px;
  justify-content: center;
  width: 100%;
}

.uploadButton {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  position: relative;

  & > button:focus {
    box-shadow: none;
  }

  &:active > button {
    box-shadow: none !important;
    color: $gray-700;
  }
}

.dragging {
  pointer-events: none;

  &::after {
    border-radius: $roundness-m;
    border: 2px dashed $black;
    bottom: $space-xxxs;
    content: '';
    left: $space-xxxs;
    position: absolute;
    right: $space-xxxs;
    top: $space-xxxs;
  }
}
.wrap {
  align-items: center;
  display: flex;
  justify-content: center;
}
.input {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: block;
  font-size: 100px;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.dropFileText {
  pointer-events: none;
}

.error {
  color: $red-800;
  padding: 0 $space-s;
  text-align: center;

  & h3 {
    margin-bottom: $space-xxs;
  }
}

.fileListing {
  width: 100%;
  background-color: red;
}
