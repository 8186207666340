@import '../../styles/variables.scss';

a.row {
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}

.fullWidth{
  min-width: 180px;
}
.row {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  padding: 0 $space-xxs;
  height: 75px;
  //width: auto;
  //min-width: 230px;
  width: 100%;
  float: left;
  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }
}


.center{

  margin-left: $space-xl;
}
.multiwrap{
 
  display: flex;
  margin: 0;
  //width: 100%;
  max-width:250px;

}
.size--l {
  height: 90px;

  .subtitle {
    margin-top: $space-xxs;
  }

}

.title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-height: 1.2;
  @media screen and (max-width: $mobile) {
    line-height: 1.0;
    font-size: 1.0rem;
  }
  
}



.subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-height: 1.2;
  @media screen and (max-width: $mobile) {
    line-height: 1;
  font-size: 0.8rem;
  }


  
  
}

.subtitle {
  color: $gray-700;
  margin-top: $space-xxxs;
}

.left {
  padding-right: $space-xxs;
  padding-left: $space-xxs;
}

.rightMobile {
  
  color: $gray-700;
  align-items: center;
  height:75px;
  display: flex;
  //padding: 0 $space-m;
}


.outerDiv{
width: 100%;
align-items: center;
border-bottom: 1px solid $gray-300;
display: flex;
}
.leftDiv
{

//left:0;
//width: 80%;
//float: left;
width: 100%;

@media all and (width: 320px) {
 // width: 100%;
}
@media screen and (max-width: $mobile) {
 // width: 60%;
}

@media screen and (max-width: $ipad) {
 // width: 60%;
}
@media screen and (min-width: $desktop-s) {
  //width: 85%;
}


}
.rightDiv
{
  padding: 0 ($space-xs)+2;
}



._rightDiv
{
right:0;
width: 20%;
float: right;
@media screen and (max-width: 320px) {
  width: 100%;
}
@media screen and (max-width: $mobile) {
  width: 40%;
}
@media screen and (max-width: $ipad) {
  width: 40%;
}
@media screen and (min-width: $desktop-s) {
  width: 15%;
}

@media screen and (width: $ipad) and (height: $ipadHeight) {
  width: 25%;
  
}
}		