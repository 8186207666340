@import '../../styles/variables.scss';

.video {
  background: $gray-300;
  height: 0;
  padding-top: 56.25%;
  width: 100%;
}
.ontop{
  z-index: 999999;
}