@import '../../styles/variables.scss';

.sentinel {
  display: flex;
  height: 100%;
  overflow: auto;
}

.sentinel--nav-showing {
  @media screen and (max-width: $mobile) {
    overflow: hidden;

    .nav {
      opacity: 1;
      transition: transform 0.125s ease-out, opacity 0s linear;
    }

    .page {
      transform: translateX(80px);
    }
  }
}

.nav {
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transition: transform 0.125s ease-out, opacity 0s linear 0.125s;
  z-index: 1;

  @media screen and (max-width: $mobile) {
    opacity: 0;
  }
}

.page {
  background-color: $gray-100;
  flex: 1;
  height: 100%;
  margin-left: 80px;
  position: relative;
  transition: transform 0.125s ease-out;
  z-index: 2;

  @media screen and (max-width: $mobile) {
    margin-left: 0;
  }
}
