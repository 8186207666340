@import '../../styles/variables.scss';
.center{
  padding: $space-xs;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}
.wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  

  & > * {
    min-width: 10%;
    width:100%;
    margin-bottom: $space-xxs;
  }

  @media screen and (max-width: $tablet) {
    padding: $space-m;
  }

  @media screen and (max-width: $mobile) {
    flex-wrap: wrap;
    padding: $space-m;

    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-s;

      &:nth-child(1n + 3) {
        margin-bottom: 0;
      }
    }
  }
}
.header {
  display: flex;
 
  text-align: left;
  margin-top: 0;
  vertical-align: top;
  //padding: $space-s 0 0 $space-s;
  
 
  
  @media screen and (max-width: $mobile) {
   // flex-direction: column;
  
  }
 
}
.header > * {
  flex: 1;
  $font-stack: 'Roboto', sans-serif;
  margin: 0 $space-xxxs $space-xxxs 0;
 
  @media screen and (max-width: $mobile) {
    margin-right: 0;
   
    &:last-child {
      margin-right: inherit;
    }
  }
}

.inputRow {
  padding: $space-xxxs;
  display: flex;
  $font-stack: 'Roboto', sans-serif;
  cursor: pointer;
  min-height: 36px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  //padding: $space-s 0 0 $space-s;
  align-items: center;
  border-bottom: 1px solid $gray-300;
  padding-left: $space-xxxs;

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }

    color: $gray-800;
    font-size: 1.071428571rem;
    line-height: 1.4;
    font-weight: 400;
  
  @media screen and (max-width: $mobile) {
   // flex-direction: column;
  
  }
}
.left {
  padding-right: $space-s;
}

.right {
  color: $gray-700;
  margin-left: auto;
  padding-left: $space-s;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}
.rightnopad {
  color: $gray-700;
  
  margin-top: $space-s;
  padding-right: $space-m;
  border-bottom: 1px solid $gray-300;
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.rightMobile {
  color: $gray-700;
  display: none;
  margin-top: $space-xxxs;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.inputRow > * {
  flex: 1;
  width:14px;
  min-height: 36px;
  //max-height: 56px;
  padding-left:$space-xxxs;
  border-right: 1px solid $gray-200;
  padding: $space-xxs;


 @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape)
 {
   padding: 0%;
 }


  &:nth-child(even) {
  
  }
  &:last-of-type{
    margin-right: 0;
    border-bottom: 0;
  }
  &:last-child {
    margin-right: 0;
    border-bottom: 0;
    border-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;
   
    &:last-child {
      margin-right: inherit;
      border-bottom: 0;
    }
  }
}
.inputRowExtra{
  flex: 1;
  width:100%;
  min-height: 36px;
  padding-left:$space-xxxs;
  border-right: 1px solid $gray-200;
  padding: $space-xxs;
  background-color:  $gray-200;

 
}
.row2 {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  height: 74px;
  padding: 0 $space-m;
  width: 100%;
  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: 0 $space-xs;
    height: 85px;
  }
}
.multiwrap{
 
  display: flex;
  margin: 0;
  
}
.size--l {
  height: 90px;

  .subtitle {
    margin-top: $space-xxs;
  }

  @media screen and (max-width: $mobile) {
    height: 124px;
    padding: 0 $space-m;

    .rightMobile {
      margin-top: $space-xs;
    }
  }
}