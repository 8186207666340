@import '../../styles/variables.scss';

.assetWrap {
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  padding: $space-m;
}
.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.error {
  color: $red-600;
  margin-bottom: $space-xs;
  @media screen and (max-width: $mobile) {
  
    margin-bottom: 0;
    
  }
}
.body {
  max-width: 380px;
  padding-top: $space-xs;
  text-align: center;
  display:block;
  }