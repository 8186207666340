.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;     
  transition: opacity 0.15s linear;
  pointer-events: none;
}

.fade.in {
  opacity: 1;
  pointer-events: unset;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);    
    transform: translate3d(0, 0, 0);
  }

  16.7%, 50%, 83.3% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  33.3%, 66.7% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  16.7%, 50%, 83.3% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  33.3%, 66.7% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes slideDownIn {
  0% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes slideDownIn {
  0% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes slideLeftIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes slideLeftIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes slideRightIn {
  0% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes slideRightIn {
  0% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes messageMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes messageMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes messageMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 150px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes messageMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 150px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes alertMoverIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes alertMoverIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes alertMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 60px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes alertMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 60px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes notificationMoverIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes notificationMoverIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px;
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

@-webkit-keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  100% {
    opacity: 0;
    height: 100%;
  }
}

@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  100% {
    opacity: 0;
    height: 100%;
  }
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }

  100% {
    max-width: 0;
    opacity: 0;
  }
}

@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }

  100% {
    max-width: 0;
    opacity: 0;
  }
}

@-webkit-keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@-webkit-keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Apple-System;
  src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
}

.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
}

.rs-btn:hover,.rs-btn:focus {
  color: #575757;
  text-decoration: none;
}

.rs-btn:active,.rs-btn.rs-btn-active {
  outline: 0;
  background-image: none;
}

.rs-btn.rs-btn-disabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}

a.rs-btn {
  text-decoration: none;
}

.rs-btn-default {
  overflow: hidden;
  position: relative;
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn-default {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-disable-line property-no-vendor-prefix */
  }
}

.rs-btn-default {
  color: #575757;
  background: #f7f7fa;
}

.rs-btn-default {
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
}

.rs-btn-default:focus {
  color: #575757;
  background-color: #e5e5ea;
}

.rs-btn-default:hover {
  color: #575757;
  background-color: #e5e5ea;
}

.rs-btn-default:active,.rs-btn-default.rs-btn-active {
  color: #575757;
  background-color: #d9d9d9;
  background-image: none;
}

.rs-btn-default:active:hover,.rs-btn-default.rs-btn-active:hover,.rs-btn-default:active:focus,.rs-btn-default.rs-btn-active:focus {
  color: #575757;
  background-color: #d9d9d9;
}

.rs-btn-default.rs-btn-disabled,.rs-dropdown-disabled .rs-btn-default,.rs-btn-default.rs-btn-disabled:hover,.rs-dropdown-disabled .rs-btn-default:hover,.rs-btn-default.rs-btn-disabled:focus,.rs-dropdown-disabled .rs-btn-default:focus,.rs-btn-default.rs-btn-disabled:active,.rs-dropdown-disabled .rs-btn-default:active,.rs-btn-default.rs-btn-disabled:active:focus,.rs-dropdown-disabled .rs-btn-default:active:focus {
  opacity: 1;
  color: #c5c6c7;
  background-color: #f7f7fa;
}

.rs-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.rs-btn-group > .rs-btn {
  position: relative;
  float: left;
}

.rs-btn-group > .rs-btn,.rs-btn-group > .rs-dropdown > .rs-dropdown-toggle > .rs-btn {
  border-radius: 0;
}

.rs-btn-group .rs-btn + .rs-btn,.rs-btn-group .rs-btn + .rs-btn-group,.rs-btn-group .rs-btn-group + .rs-btn,.rs-btn-group .rs-btn-group + .rs-btn-group {
  margin-left: -1px;
}

.rs-btn-group > .rs-btn-group {
  float: left;
}

.rs-btn-group > .rs-btn-group:not(:first-child):not(:last-child) > .rs-btn {
  border-radius: 0;
}

.rs-btn-group > .rs-btn-group:first-child:not(:last-child) > .rs-btn:last-child,.rs-btn-group > .rs-btn-group:first-child:not(:last-child) > .rs-dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rs-btn-group > .rs-btn-group:last-child:not(:first-child) > .rs-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rs-btn-group .dropdown-toggle:active {
  outline: 0;
}

.rs-btn-group > .rs-btn + .rs-dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.rs-btn .rs-caret {
  margin-left: 0;
}

.rs-btn-toolbar {
  line-height: 0;
}

.rs-calendar {
  background-color: #fff;
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}

.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px;
}

.rs-calendar-panel .rs-calendar-header::before,.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}

.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}

.rs-calendar-panel .rs-calendar-header::before,.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}

.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}

.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}

.rs-calendar-panel .rs-calendar-header-forward,.rs-calendar-panel .rs-calendar-header-title,.rs-calendar-panel .rs-calendar-header-backward {
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
}

.rs-calendar-panel .rs-calendar-header-forward,.rs-calendar-panel .rs-calendar-header-backward {
  width: 31px;
  float: none !important;
}

.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: transparent;
}

.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 12px;
  margin-right: 12px;
  top: 54px;
  width: calc(100% - 24px);
}

.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0;
}

.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}

.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px;
}

.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px;
}

.rs-calendar-table-cell-in-range {
  position: relative;
}

.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 3px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}

.rs-calendar-table-cell-in-range::before {
  background-color: rgba(255, 247, 204, 0.5);
}

.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}

.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;
}

.rs-calendar-header::before,.rs-calendar-header::after {
  content: ' ';
  display: table;
}

.rs-calendar-header::after {
  clear: both;
}

.rs-calendar-header::before,.rs-calendar-header::after {
  content: ' ';
  display: table;
}

.rs-calendar-header::after {
  clear: both;
}

.rs-calendar-header-month-toolbar,.rs-calendar-header-time-toolbar {
  display: inline-block;
}

.rs-calendar-header-month-toolbar {
  float: left;
}

.rs-calendar-header-time-toolbar {
  float: right;
}

.rs-calendar-header-title,.rs-calendar-header-backward,.rs-calendar-header-forward {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  /* stylelint-enable */
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #8e8e93;
  background-color: transparent;
  /* stylelint-disable */
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.66666667;
  border-radius: 6px;
  /* stylelint-enable */
  padding: 2px 2px;
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-calendar-header-title:hover,.rs-calendar-header-backward:hover,.rs-calendar-header-forward:hover,.rs-calendar-header-title:focus,.rs-calendar-header-backward:focus,.rs-calendar-header-forward:focus {
  color: #575757;
  text-decoration: none;
}

.rs-calendar-header-title:active,.rs-calendar-header-backward:active,.rs-calendar-header-forward:active,.rs-calendar-header-title.rs-btn-active,.rs-calendar-header-backward.rs-btn-active,.rs-calendar-header-forward.rs-btn-active {
  outline: 0;
  background-image: none;
}

.rs-calendar-header-title:focus,.rs-calendar-header-backward:focus,.rs-calendar-header-forward:focus,.rs-calendar-header-title:hover,.rs-calendar-header-backward:hover,.rs-calendar-header-forward:hover {
  color: #fff;
  background: #f7f7fa;
}

.rs-calendar-header-title:active,.rs-calendar-header-backward:active,.rs-calendar-header-forward:active,.rs-calendar-header-title.rs-btn-active,.rs-calendar-header-backward.rs-btn-active,.rs-calendar-header-forward.rs-btn-active {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}

.rs-calendar-header-title:active:hover,.rs-calendar-header-backward:active:hover,.rs-calendar-header-forward:active:hover,.rs-calendar-header-title.rs-btn-active:hover,.rs-calendar-header-backward.rs-btn-active:hover,.rs-calendar-header-forward.rs-btn-active:hover,.rs-calendar-header-title:active:focus,.rs-calendar-header-backward:active:focus,.rs-calendar-header-forward:active:focus,.rs-calendar-header-title.rs-btn-active:focus,.rs-calendar-header-backward.rs-btn-active:focus,.rs-calendar-header-forward.rs-btn-active:focus {
  color: #fff;
  background-color: #b2b1f0;
}

.rs-calendar-header-title.rs-btn-disabled,.rs-calendar-header-backward.rs-btn-disabled,.rs-calendar-header-forward.rs-btn-disabled,.rs-calendar-header-title.rs-btn-disabled:hover,.rs-calendar-header-backward.rs-btn-disabled:hover,.rs-calendar-header-forward.rs-btn-disabled:hover,.rs-calendar-header-title.rs-btn-disabled:focus,.rs-calendar-header-backward.rs-btn-disabled:focus,.rs-calendar-header-forward.rs-btn-disabled:focus,.rs-calendar-header-title.rs-btn-disabled:active,.rs-calendar-header-backward.rs-btn-disabled:active,.rs-calendar-header-forward.rs-btn-disabled:active,.rs-calendar-header-title.rs-btn-disabled:hover:active,.rs-calendar-header-backward.rs-btn-disabled:hover:active,.rs-calendar-header-forward.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}

.rs-calendar-header-title.rs-btn-disabled,.rs-calendar-header-backward.rs-btn-disabled,.rs-calendar-header-forward.rs-btn-disabled,.rs-calendar-header-title.rs-btn-disabled:hover,.rs-calendar-header-backward.rs-btn-disabled:hover,.rs-calendar-header-forward.rs-btn-disabled:hover,.rs-calendar-header-title.rs-btn-disabled:focus,.rs-calendar-header-backward.rs-btn-disabled:focus,.rs-calendar-header-forward.rs-btn-disabled:focus,.rs-calendar-header-title.rs-btn-disabled:active,.rs-calendar-header-backward.rs-btn-disabled:active,.rs-calendar-header-forward.rs-btn-disabled:active {
  opacity: 1;
}

.rs-calendar-header-title:focus,.rs-calendar-header-backward:focus,.rs-calendar-header-forward:focus,.rs-calendar-header-title:hover,.rs-calendar-header-backward:hover,.rs-calendar-header-forward:hover {
  color: #575757;
}

.rs-calendar-header-title:active,.rs-calendar-header-backward:active,.rs-calendar-header-forward:active,.rs-calendar-header-title.rs-btn-active,.rs-calendar-header-backward.rs-btn-active,.rs-calendar-header-forward.rs-btn-active {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}

.rs-calendar-header-title:active:hover,.rs-calendar-header-backward:active:hover,.rs-calendar-header-forward:active:hover,.rs-calendar-header-title.rs-btn-active:hover,.rs-calendar-header-backward.rs-btn-active:hover,.rs-calendar-header-forward.rs-btn-active:hover,.rs-calendar-header-title:active:focus,.rs-calendar-header-backward:active:focus,.rs-calendar-header-forward:active:focus,.rs-calendar-header-title.rs-btn-active:focus,.rs-calendar-header-backward.rs-btn-active:focus,.rs-calendar-header-forward.rs-btn-active:focus {
  color: #272c36;
  background-color: #e5e5ea;
}

.rs-calendar-header-title {
  margin: 0 0;
}

.rs-calendar-header-btn-disabled,.rs-calendar-header-btn-disabled:hover,.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}

.rs-calendar-header-btn-disabled::after {
  display: none !important;
}

.rs-calendar-header-backward,.rs-calendar-header-forward {
  font-size: 0;
  width: 24px;
}

.rs-calendar-header-backward::before,.rs-calendar-header-forward::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  font-size: 14px;
}

.rs-calendar-header-backward::before {
  content: '\ea0a';
}

.rs-calendar-header-forward::before {
  content: '\ea0c';
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right;
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}

.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}

/* rtl:begin:ignore */

/* stylelint-disable-next-line */

[dir='rtl'] .rs-calendar-header-backward,[dir='rtl'] .rs-calendar-header-forward {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* rtl:end:ignore */

.rs-calendar-view {
  padding: 4px 12px 12px;
}

.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.rs-calendar-table-row {
  display: table-row;
}

.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
}

.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  color: #ffca27;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  opacity: 0.15;
}

.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}

.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #ffca28;
  padding: 4px;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content,.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}

.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 40px;
  border-top: 1px solid #e5e5ea;
  margin-top: 4px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-month-dropdown-scroll {
  height: 230px;
}

.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 52px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted #e5e5ea;
}

.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 1px * 12 ) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
  width: calc((100% - 1px * 12 - 1px) / 6)\9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}

@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}

.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}

.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
}

.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}

.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #ffffff;
  border-color: #ff7b00;
  background-color: #ff7b00;
}

.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 40px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}

.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
  display: block;
  color: #575757;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: #ff7b00;
  color: #fff;
}

.rs-calendar-time-dropdown-cell,.rs-calendar-time-dropdown-cell:hover,.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}

.rs-calendar-time-dropdown-cell:hover {
  color: #575757;
  background-color: #f2faff;
}

.rs-calendar-time-dropdown-cell-disabled,.rs-calendar-time-dropdown-cell-disabled:hover,.rs-calendar-time-dropdown-cell-disabled:focus {
  color: #c5c6c7;
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* stylelint-disable-next-line */

/* rtl:begin:ignore */

/* stylelint-disable-next-line */

/* rtl:end:ignore */

.rs-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret {
  top: 7px;
}

.rs-picker-toolbar {
  padding: 12px;
  border-top: 1px solid #e5e5ea;
}

.rs-picker-toolbar::before,.rs-picker-toolbar::after {
  content: ' ';
  display: table;
}

.rs-picker-toolbar::after {
  clear: both;
}

.rs-picker-toolbar::before,.rs-picker-toolbar::after {
  content: ' ';
  display: table;
}

.rs-picker-toolbar::after {
  clear: both;
}

.rs-picker-toolbar-ranges {
  display: inline-block;
  margin-left: -10px;
  margin-top: 5px;
}

.rs-picker-toolbar-right {
  float: right;
}

.rs-picker-toolbar-option {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  /* stylelint-enable */
  /* stylelint-disable */
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
  color: #777777;
  font-weight: normal;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.rs-picker-toolbar-option:hover,.rs-picker-toolbar-option:focus {
  color: #575757;
  text-decoration: none;
}

.rs-picker-toolbar-option:active,.rs-picker-toolbar-option.rs-btn-active {
  outline: 0;
  background-image: none;
}

.rs-picker-toolbar-option,.rs-picker-toolbar-option:active,.rs-picker-toolbar-option.rs-btn-active {
  background-color: transparent;
}

.rs-picker-toolbar-option,.rs-picker-toolbar-option:hover,.rs-picker-toolbar-option:focus,.rs-picker-toolbar-option:active {
  border-color: transparent;
}

.rs-picker-toolbar-option:hover,.rs-picker-toolbar-option:focus {
  color: #024bb3;
  text-decoration: underline;
  background-color: transparent;
}

.rs-picker-toolbar-option:active,.rs-picker-toolbar-option.rs-btn-active {
  color: #003b94;
}

.rs-picker-toolbar-option:active:hover,.rs-picker-toolbar-option.rs-btn-active:hover,.rs-picker-toolbar-option:active:focus,.rs-picker-toolbar-option.rs-btn-active:focus {
  color: #003b94;
}

.rs-picker-toolbar-option.rs-btn-disabled:hover,.rs-picker-toolbar-option.rs-btn-disabled:focus {
  color: #085cc9;
  text-decoration: none;
}

.rs-picker-toolbar-option:not(:last-child) {
  position: relative;
}

.rs-picker-toolbar-option:not(:last-child)::before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #e5e5ea;
  position: absolute;
  top: 0;
  right: 0;
}

.rs-picker-toolbar-option-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-picker-toolbar-option-disabled,.rs-picker-toolbar-option-disabled:hover,.rs-picker-toolbar-option-disabled:focus,.rs-picker-toolbar-option-disabled:active {
  text-decoration: none;
}

.rs-picker-toolbar-right-btn-ok {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  /* stylelint-enable */
  /* stylelint-disable */
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
  /* stylelint-disable-next-line */
  color: #fff;
  background-color: #ff7b00;
}

.rs-picker-toolbar-right-btn-ok:hover,.rs-picker-toolbar-right-btn-ok:focus {
  text-decoration: none;
}

.rs-picker-toolbar-right-btn-ok:active,.rs-picker-toolbar-right-btn-ok.rs-btn-active {
  outline: 0;
  background-image: none;
}

.rs-picker-toolbar-right-btn-ok:focus {
  color: #fff;
  background-color: #ffca27;
}

.rs-picker-toolbar-right-btn-ok:hover {
  color: #fff;
}

.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color: #ffca27;
}

.rs-picker-toolbar-right-btn-ok:active,.rs-picker-toolbar-right-btn-ok.rs-btn-active {
  color: #fff;
  background-color: #ffca28;
}

.rs-picker-toolbar-right-btn-ok:active:hover,.rs-picker-toolbar-right-btn-ok.rs-btn-active:hover,.rs-picker-toolbar-right-btn-ok:active:focus,.rs-picker-toolbar-right-btn-ok.rs-btn-active:focus {
  color: #fff;
  background-color: #ffca28;
}

.rs-picker-toolbar-right-btn-ok:active,.rs-picker-toolbar-right-btn-ok.rs-btn-active {
  background-image: none;
}

.rs-picker-toolbar-right-btn-ok.rs-btn-disabled:hover,.rs-picker-toolbar-right-btn-ok.rs-btn-disabled:focus {
  background: #ff7b00;
}

.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
  background-color: #ff7b00;
}

.rs-picker-date .rs-picker-toggle-caret::before {
  content: '\f073' !important;
  line-height: 17px;
}

.rs-picker-menu .rs-picker-toolbar {
  max-width: 280px;
}

.rs-picker-menu .rs-calendar {
  width: 280px;
  display: block;
  margin: 0 auto;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px;
}

.rs-picker-daterange .rs-picker-toggle-caret::before {
  content: '\f073' !important;
  line-height: 17px;
}

.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  width: 255px;
  height: 278px;
  padding-bottom: 12px;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid #e5e5ea;
}

.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
}

.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none;
}

.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}

.rs-picker-daterange-menu .rs-picker-toolbar {
  margin-top: 4px;
  max-width: 510px;
}

.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #e5e5ea;
}

.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 510px;
}

.rs-dropdown-toggle-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
}

.rs-dropdown-toggle-caret::before {
  font-family: 'rsuite-icon-font' !important;
}

.rs-dropdown-placement-left-start .rs-dropdown-toggle-caret::before {
  content: '\ea0a' ;
}

.rs-dropdown-placement-right-start .rs-dropdown-toggle-caret::before {
  content: '\ea0c' ;
}

.rs-dropdown-placement-top-start .rs-dropdown-toggle-caret::before {
  content: '\ea0e';
}

.rs-dropdown-placement-bottom-start .rs-dropdown-toggle-caret::before {
  content: '\ea08';
}

.rs-dropdown {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  margin-left: 4px;
}

.rs-dropdown .rs-dropdown-menu {
  position: absolute;
  z-index: 6;
  display: none;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
}

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
  cursor: not-allowed !important;
}

.rs-dropdown-toggle,.rs-dropdown-toggle.rs-btn {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.rs-dropdown-menu {
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 0;
}

.rs-dropdown-header {
  display: block;
  padding: 8px 12px;
  line-height: 1.42857143;
  color: #a6a6a6;
  border-bottom: 1px solid #e5e5ea;
  white-space: nowrap;
}

/* rtl:begin:ignore */

/* stylelint-disable-next-line */

/* rtl:end:ignore */

.rs-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

/* rtl:begin:ignore */

/* stylelint-disable-next-line */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

.rs-panel {
  border-radius: 6px;
  overflow: hidden;
}

.rs-panel-body {
  padding: 20px;
}

.rs-panel-title {
  margin: 0;
}

.rs-panel-title a {
  color: #272c36;
}

.rs-panel-title a:hover,.rs-panel-title a:focus,.rs-panel-title a:active {
  text-decoration: none;
}

.rs-panel-group {
  border-radius: 6px;
  overflow: hidden;
}

.rs-panel-group > .rs-panel {
  border: none;
}

.rs-panel-group > .rs-panel + .rs-panel {
  position: relative;
}

.rs-panel-group > .rs-panel + .rs-panel::before {
  content: '';
  position: absolute;
  top: 0;
  border-top: 1px solid #e5e5ea;
  left: 20px;
  right: 20px;
}

.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.rs-picker-disabled {
  opacity: 0.3;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
}

.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
}

.rs-picker-toggle:hover,.rs-picker-toggle:focus {
  color: #575757;
  text-decoration: none;
}

.rs-picker-toggle:active,.rs-picker-toggle.rs-btn-active {
  outline: 0;
  background-image: none;
}

.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}

.rs-picker-default .rs-btn,.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #ffca28;
}

/* stylelint-disable-next-line */

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px;
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}

.rs-picker-date .rs-picker-toggle.rs-btn,.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* stylelint-disable-next-line */

.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
}

.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}

.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: #ffca28;
}

.rs-picker-toggle-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
}

.rs-picker-toggle-caret::before {
  font-family: 'rsuite-icon-font' !important;
}

.rs-picker-placement-left-start .rs-picker-toggle-caret::before {
  content: '\ea0a' ;
}

.rs-picker-placement-right-start .rs-picker-toggle-caret::before {
  content: '\ea0c' ;
}

.rs-picker-placement-top-start .rs-picker-toggle-caret::before {
  content: '\ea0e';
}

.rs-picker-placement-bottom-start .rs-picker-toggle-caret::before {
  content: '\ea08';
}

.rs-picker-toggle-caret::before {
  content: '\ea08';
}

.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  /* stylelint-disable-next-line */
}

.rs-picker-default .rs-btn,.rs-picker-default .rs-picker-toggle {
  background: #fff !important;
}

.rs-picker-default.rs-picker-disabled .rs-picker-toggle,.rs-picker-default.rs-picker-disabled .rs-btn {
  background: #f7f7fa !important;
}

.rs-placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-row {
  margin-left: -5px;
  margin-right: -5px;
}

.rs-row::before,.rs-row::after {
  content: ' ';
  display: table;
}

.rs-row::after {
  clear: both;
}

.rs-row::before,.rs-row::after {
  content: ' ';
  display: table;
}

.rs-row::after {
  clear: both;
}

.rs-table {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.rs-table-row {
  overflow: hidden;
  position: absolute;
  height: 36px;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #f2f2f5;
}

.rs-table-row-header {
  color: #8e8e93;
  background: #fff;
}

.rs-table-row-header .rs-table-cell {
  background: #fff;
}

.rs-table-row,.rs-table-cell-group,.rs-table-cell {
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}

.rs-table-header-row-wrapper {
  z-index: 2;
  position: relative;
}

.rs-table-body-row-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.rs-table-cell {
  height: 36px;
  border-style: solid;
  border-width: 0;
  border-color: #f2f2f5;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: normal;
  background: #fff;
}

.rs-table-cell-content {
  padding: 13px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 10px 10px;
  font-size: 12px;
  line-height: 1.66666667;
}

.rs-btn-toggle {
  height: 24px;
  min-width: 44px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #d9d9d9;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rs-btn-toggle::after {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
  border-radius: 9px;
}

.rs-btn-toggle:active::after {
  width: 21.6px;
}

.rs-btn-toggle:hover {
  background-color: #c5c6c7;
}

.rs-btn-toggle::after {
  content: '';
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  -webkit-transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
}

.rs-btn-toggle-disabled,.rs-btn-toggle-disabled::after {
  cursor: not-allowed;
}

.rs-btn-toggle-disabled,.rs-btn-toggle-disabled:hover {
  background-color: #f7f7fa;
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -5px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -5px;
  }
}

/* rtl:begin:ignore */

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -5px;
  }
}

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -5px;
  }
}

/* rtl:end:ignore */

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

