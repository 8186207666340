@import '../../styles/variables.scss';

.uploadButton {
  cursor: pointer;

  position: absolute;
  right: 5px;
  top: 5px;

  &>button:focus {
    box-shadow: none;
  }

  &:active>button {
    box-shadow: none !important;
    color: $gray-700;
  }
}

.mainInfo {
  padding-top: $space-m;
}

.noBottomMargin {
  margin-bottom: 0;
}

.delete {
  text-align: center;
}

.floatright {
  position: absolute;
  right: 5px;
  top: 5px;
}

.floatleft {
  position: absolute;
  right: 27px;
  top: 70px;
  cursor: pointer;
}

.wrapper {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.w30 {
  margin-left: 24px;
  margin-right: 20px;
  width: 10%;
  align-items: center;

}

.w70 {
  align-items: center;

  width: 90%;
  height: 35px;
}

.smallwrap {
  margin-right: 0;
  margin-left: 20px;
  width: 20px;
}

.break {

  height: 1px;
  width: 100%;
  background: $gray-500;
  margin: $space-l 0 $space-l, 0;
}

.input {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer !important;
  display: block;
  font-size: 100px;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.input::before {
  content: '&nbsp;';
  -webkit-user-select: none;
  cursor: pointer;
}

.wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.text {
  flex: 1;
  padding: 0 $space-m;

  @media screen and (max-width: $mobile) {
    flex-basis: 75%;
  }
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}

.subtitle {
  color: $gray-700;
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}


.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: 0 $space-m 0 $space-m;
  margin-top: $space-m;
}