@import '../../styles/variables.scss';

.frameWrap {
  max-width: 380px;
  text-align: center;
  width: 100%;
  margin-top:48px;

  @media screen and (max-height: 1000px) {
    align-self: flex-start;
    margin: $space-xl 0;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    margin: $space-m;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    max-width: 280px;
    width: 100%;
    height:80%;
  }
}

.logoWrap {
  padding: $space-m $space-m $space-xs $space-xl;
  @media all and (device-width: 1280px) and (device-height: 720px) {
    padding: 0;
  }
}

.heading {
  margin-bottom: $space-xxs;
  @media all and (device-width: 1280px) and (device-height: 720px) {
    margin-bottom: 0;
  }
}

.forgotPassword {
  display: inline-block;
  margin: $space-m 0 $space-l;
}

.createAccount {
  color: $gray-700;

  a {
    color: $gray-900;
    display: inline-block;
    margin-top: $space-xxs;
  }
}
