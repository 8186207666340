@import '../../styles/variables.scss';
@import '../../styles/shapes.scss';

.wrap {
  position: relative;
}

.wrappadding {
  position: relative;
  padding-bottom: $space-xxs;
  font-size: 16px;
}

.wrapfull {
  position: relative;
  width: 100%;
}

.loadingSpinner {
  justify-content: center;
  padding: 8px;
}

.target {
  align-items: center;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer;
  display: flex;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-xxxs 0 $space-xxs;
  position: relative;
  text-align: left;
  user-select: none;
  //white-space: nowrap;
  word-wrap: unset;
  height: 100%;
  width: 100%;

  @include focus;

  &:focus {
    border: 1px solid $gray-500;
  }

  &:active {
    color: $gray-700;
  }

  &[disabled] {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  @media screen and (max-width: $mobile) {
    width: auto;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: $space-xxxs;
  }
}

.primary {
  background-color: $gray-900;
  border: 1px solid darken($gray-900, 10%);
  color: $white;
}

.popover {
  border-radius: 3px;
  box-shadow: $depth-300;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -4px;
  transition: visibility 0s linear 0.175s, opacity 0.175s ease-out;
  visibility: hidden;
  z-index: 9;

  &:focus {
    outline: none;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition: visibility 0s linear, opacity 0.175s ease-out;
  }
}

.icon {
  align-items: center;
  color: inherit;
  display: flex;
  position: relative;
  padding: $space-xxxxs $space-xxxs $space-xxxxs;
}

.flag {
  position: absolute;
  bottom: 2px;
  right: 0px;
  border: 1px solid #fff;
  box-sizing: content-box;
  @include flag;
  border-radius: 6px;
}

.text {
  padding: 0 $space-xs 0 $space-xxs;
  white-space: pre-wrap;
  line-height: 1.4;
  padding-top: $space-xxs;
  padding-bottom: $space-xxs;
}

.carret {
  margin-left: auto;
  @include carret;
}

.primary .carret {
  border-top-color: $white;
}

.primary:active .carret {
  opacity: 0.65;
}

.assetDashboard {
  @media only screen and (min-device-width: $mobile) and (max-device-width: 1025px) and (orientation:landscape) {
    margin-top: 10px;

  }
}

.assetDashboard>span {
  @media only screen and (min-device-width: $mobile) and (max-device-width: 1025px) and (orientation:landscape) {
    display: none;

  }
}