@import '../../styles/variables.scss';

.nav {
  align-items: center;
  background-color: $gray-900;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: auto;
  padding: 0 0 $space-xs;
  width: 80px;
  @media all and (device-width: 1280px) and (device-height: 720px) {
    width: auto;
    padding: 0;
  }
}

.nav > a:focus {
  @include focus-dark;
}

.logoLink {
  padding: $space-xxs;
  margin-top: 12px;
  margin-bottom: $space-s;

  @media all and (device-width: 1280px) and (device-height: 720px) {
    padding: 0px;
    margin-top: 2px;
    margin-bottom: 0px;
  
  }
}

.navItemLink {
  margin-bottom: $space-xs;
  @media all and (device-width: 1280px) and (device-height: 720px) {
    margin-bottom: $space-xxxs;
  
  }
}

.accountLink {
  margin-top: auto;
}

.break {
  height: 1px;
  width: 55px;
  background: $gray-800;
  margin: $space-xs 0 $space-l;
}
