@import '../../styles/variables.scss';

.signUpForm {
  text-align: left;
}

.betaDisclaimer {
  text-align: left;
}

.submit {
  text-align: center;
}
.header {
  color: $gray-700;
}

.acceptedcards {
  background: url('../../images/accepted_cards.png') no-repeat left top;
  margin-bottom: 20px;
  height: 30px;
}
.red{
  color:$red-600;
  display: inline-block;
}
.expiration {

  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 10px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
.selected{
  border-radius: $roundness-m;
  box-shadow: 0 0 32px 2px rgba(250, 92, 7, 0.405);
}

.margin {

  
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 10px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  
  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
ul {
    list-style-type: none;
}

* {
  box-sizing: border-box;
}

/* Create three columns of equal width */
.columns {
  float: left;
  width: 33.3%;
  padding: 8px;
}

/* Style the list */
.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Add shadows on hover */
.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
  
}
.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile) {
    height: 120px;
  }
  & img {
   // max-height: 100%;
   // max-width: 100%;
  }

}
/* Pricing header */
.price .header {
  background-color: $gray-700;
  color: white;
  font-size: 18px;
}

/* List items */
.price li {
  border-bottom: 1px solid #eee;
  padding: $space-xs;
  text-align: center;
  list-style-type: none;
  margin-bottom: 0px;
}

/* Grey list item */
.price .grey {
  background-color: $gray-200;
  font-size: 20px;
}

/* The "Sign Up" button */
.button {
  background-color: $brand;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

/* Change the width of the three columns to 100%
(to stack horizontally on small screens) */
@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}

.wrap {
  align-items: center;
  display: flex;
  justify-content: center;
}
@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.overlay {
  background-color: rgba($black, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
 
}

.frame {
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
  max-height: 84vh;
  max-width: 720px;
  min-height: 84vh;
  overflow-y: hidden;
  padding-bottom: 84px;
  position: relative;
  width: 90%;
}
.framesmall {
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
  max-height: 35vh;
  max-width: 720px;
  min-height: 27vh;
  overflow-y: hidden;
  
  position: relative;
  width: 100%;
  @media screen and (max-width: $mobile) {
    max-height: 500px;
    //height: 360px;
   
  }

  @media screen and (max-width: $ipadHeight) {
    max-height: 500px;
    height: 360px;
  }

  @media screen and (min-width: $ipadHeight+1) and (max-width: $desktop-l) {
    min-height: 40vh;
  }

  @media all and (width: 1280px) and (height: 720px) {
    max-height: 500px;
    height: 360px;
  }

  @media all and (min-width:  $desktop-l+1) and (max-width: ($desktop-xl)-1) {
   
    min-height: 35vh;
  }

}

.scrollWrap {
  -webkit-overflow-scrolling: touch;
  bottom: 84px;
  left: 0;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
}

.innerFrame {
  padding: $space-m $space-m 0 $space-m;
}
.addDeviceButton{
  margin-top: 28px;
}
.row,.row_underline{
  margin:$space-xxxs;
}
.row_underline{
  border-top: 1px solid #d9d9d9;
    margin-top: $space-xxs;
    padding-top: $space-xs;
}
.summary{
  align-items: center;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  display: inline-grid;
  flex-wrap: wrap;
  padding: $space-xxxs $space-xxs;
  width: 100%;
  background-color: $gray-100;
  margin-bottom: $space-m;
}

.left{
  float:left;
}
.right{
  float:right;
}
.footer {
  align-items: center;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid #d9d9d9;
  bottom: 0;
  padding-top: $space-xs;
  display: flex;
  justify-content: flex-end;
  left: 0;
  margin-top: 14px;
  width: 100%;
  z-index: 9;
  padding-right: 24px;


  @media screen and (min-width: $ipad) {
    position: absolute; 
    margin-bottom: $space-xs;
  }

  @media screen and (device-width: $ipad) and (orientation: landscape) {
    position: relative; 
  }
      


  @media screen and (max-width: $mobile) {
    padding: $space-xs;
  }
  & > * {
    margin-left: $space-xxs;
  }
}

.touch {
  .overlay {
    background-color: $white;
  }

  .frame {
    border-radius: 0;
    height: 100%;
    max-height: none;
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
.footer button
{
  padding: 0 16px;
}
}


.devicesList{
  overflow-y: auto;
  height: 200px;
  & > * {
    padding-bottom: $space-xxs;
  }
}

.autocomplete {
  max-width: 780px;
  text-align: left;
  width: 100%;
  align-items: flex-start;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    height: 36px;
    padding: 0 14px;
    position: relative;
    transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
    width: 100%;
    &:focus {
      border: 1px solid $gray-500;
      box-shadow: 0 0 0 3px #d9d9d9;
      border-radius: 3px;
      outline: none;
    }
}
.autocompleteDiv{
  position: absolute;
  z-index: 99999;
  text-align: left;
  max-width: 780px;
  width: 100%;
  padding-right: $space-xl;
  &:focus {
    border: 1px solid $gray-500;
  }
}
.autocompleteSuggestion{

  max-width: 780px;
  text-align: left;
  width: 100%;
  align-items: flex-start;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //border-radius: 3px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    min-height: 24px;
    padding: $space-xs;
    position: relative;
    transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
    width: 100%;
}
.autocompleteSuggestionActive{
  max-width: 780px;
  text-align: left;
  width: 100%;
  align-items: flex-start;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //border-radius: 3px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    min-height: 24px;
    padding: $space-xs;
    position: relative;
    transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
    width: 100%;

    background-color: '#ffffff';
     cursor: 'pointer';
}