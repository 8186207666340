@import '../../styles/variables.scss';

.checkbox {
  cursor: pointer;
  height: 19px;
  position: relative;
  user-select: none;
  width: 19px;
}

.input {
  appearance: none;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $gray-300;
  height: 19px;
  left: -3px;
  position: absolute;
  top: -3px;
  width: 19px;
  z-index: 0;

  &:disabled {
    background-color: $gray-200;
  }

  &:disabled ~ .label i {
    color: $gray-700;
    cursor: not-allowed;
  }

  &:checked ~ .label > i {
    opacity: 1;
  }

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;
}

.label {
  align-items: center;
  display: flex;
  height: 19px;
  justify-content: center;
  position: relative;
  width: 19px;
  z-index: 1;
  justify-content: center!important;
  align-items: center!important;
  padding-bottom: 3px;

  & > i {
    opacity: 0;
  }
}
