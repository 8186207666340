@import '../../styles/variables.scss';
.zero {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $space-xxxs;
  
  @media only screen and (min-device-width: 481px) and (max-device-width: 1025px) and (orientation:landscape) {
    word-break: break-word;  }

  & > * {
    min-width: 10%;
  }
}
.noMargin {
  margin-bottom: 0;
}

.xxs {
  margin-bottom: $space-xxs;
}

.xs {
  margin-bottom: $space-xs;
}

.s {
  margin-bottom: $space-s;
}

.m {
  margin-bottom: $space-m;
}

.l {
  margin-bottom: $space-l;
  @media all and (device-width: 1280px) and (device-height: 720px) {
    margin-bottom: $space-xs;
  }
  @media screen and (max-width: $mobile) {
    margin-bottom: $space-s;
  }
}
.xxxs {
  margin-bottom: $space-xxxs;
  
}
.xl {
  margin-bottom: $space-xl;
}
.mobile
{ 
  @media screen and (max-width: $mobile) {
    margin-bottom: $space-l;
  }
}

.px100 {
  width: 100px;
}

.px200 {
  width: 200px;
}
.px300 {
  width: 300px;
}
.px400 {
  width: 400px;
}

.max20{
//max-width:20px;
}

.max120{
 max-width:120px;
}

.max100{
 max-width:100px;
}

