/* autoprefixer grid: autoplace */
@import '../../styles/variables.scss';

.header {
  background-color: $white;
  border-bottom: 1px solid $gray-300;
  box-shadow: 0 1px 10px 2px rgba($black, 0.08);
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  grid-template-rows: 1fr;
  height: 61px;
  left: 0;
  padding: 0 $space-m;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 10;

  @media screen and (max-width: $mobile) {
    grid-template-columns: min-content 1fr max-content;
    grid-template-rows: 1fr;
    padding: 0 $space-s;
    width: 100vw;
  }

  @media screen and (max-width: $smallMobile) {
    width: 100%;
  }
}

.header > * {
  align-self: center;
}

.menuButton {
  flex: 0 0 auto;
  display: none;
  margin-right: $space-xxs;

  @media screen and (max-width: $mobile) {
    display: block;
  }

  @media screen and (min-width: ($mobile)+1) { //fix for header with buttons
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    margin-right: 0;
  }
}

.content {
  flex: 0 1 auto;
  min-width: 0;
}

.right {
  display: flex;
  flex: 0 0 auto;
  margin-left: auto;

  & > * {
    flex: 0 0 auto;
    margin-left: $space-xs;
  }
}