@import "../../styles/variables.scss";

.holder {
  background-color: $gray-300;
  margin: $space-m;
}

.deviceWrap {
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  padding-top: $space-m;
  padding-left: $space-m;
}
.center {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Set a height, or it defaults to content height */
}
.inputWrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.wrap2 {
  display: flex;
  height: 100%;
  justify-content: center;
}

.wrap2 {
  display: flex;

  & > * {
    flex: 1;
  }
}
.parent {
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Adjust the height as needed */
  width: 100%; /* Adjust the width as needed */
}

.child {
  /* Optional styling for the child */
  padding: 20px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust the height as needed */
}
.span {
  width: $space-s;
  text-align: center;
}
.icon {
  color: $green-600;
  margin-bottom: $space-xs;
}
.icon2 {
  color: $green-600;
  margin-right: $space-xs;
}
.wrap {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.icon {
  color: $green-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.blue {
  color: $blue-300;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.error {
  color: $red-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;

  @media screen and (max-width: $mobile) {
    margin-bottom: 0;
  }
}

.body {
  max-width: 380px;
  padding-top: $space-xs;
  text-align: center;
}

.bodyLeft {
  padding-top: $space-xs;
  text-align: center;
}

.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $mobile) {
    height: 120px;
  }

  & img {
    // max-height: 100%;
    // max-width: 100%;
  }
}

.wrapvert {
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;
  text-align: center;
}

.wrapvertclean {
  display: flex;
  align-items: inherit;
}

.filter {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.spacer {
  margin-bottom: $space-xs;
}

.status {
  background-color: $gray-300;
  border-radius: 3px;
  height: 7px;
  margin-top: 6px;
  width: 7px;

  &.ok {
    background-color: $green-600;
  }

  &.warning {
    background-color: $orange-300;
  }

  &.critical {
    background-color: $orange-600;
  }

  &.impacted {
    background-color: $red-600;
  }

  &.inactive {
    background-color: $gray-700;
  }

  &.maintenance {
    background-color: $orange-300;
  }
}

///@at-root
///

///////

.clearAlarm {
  //max-width: 475px;
  padding-bottom: $space-xl;
}

.wrap {
  display: flex;

  & > * {
    flex: 1;
  }
}

.spacer {
  padding-left: $space-xxxs;
  max-width: 25px;
}

.alarmSelect {
  overflow-y: auto;

  & > * {
    padding-bottom: $space-xxs;
  }
}

.alarm {
  flex: 1;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;

  @media screen and (max-width: ($smallMobile)-25) {
    // padding: 0 $space-xxs;
  }

  position: relative;
  text-align: left;
  user-select: none;
  cursor: pointer;
  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }

  // Hide the focus state when the user is selecting
  &:active:focus {
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.alarmSelected > div {
  border-radius: $roundness-m;
  border: 2px solid #ff7b00;
}

button {
  white-space: pre;
}

.button {
  flex: 1;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;
  position: relative;
  text-align: left;
  user-select: none;
  cursor: pointer;
  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }

  // Hide the focus state when the user is selecting
  &:active:focus {
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--icon {
    padding: 0 $space-xs;
  }
}

.center {
  //margin-top: 45%;
}

.row {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  min-height: 36px;
  padding: $space-xxxs;
  margin-right: $space-xs;
  word-break: break-word;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }
}

.noteReview {
  line-height: 1.4;
}

.wyswigWrapper {
  overflow: auto;
  height: 35vh;
  line-height: 1.4;

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    @media screen and (max-width: $mobile) {
      height: 15vh;
    }

    @media screen and (max-width: $ipad) {
      height: 15vh;
    }
  }

  @media screen and (max-height: $ipad) {
    height: 15vh;
  }

  @media screen and (max-width: $ipad) {
    height: 15vh;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media screen and (max-width: $mobile) {
      height: 15vh;
    }
  }
}

.wyswigWrapperSmall {
  height: 20vh;

  @media screen and (max-width: $mobile) {
    height: 15vh;
  }
}

.wyswigWrapper i,
.border i,
.wyswigWrapperSmall i {
  font-style: italic !important;
}

.wyswigOuterWrapper [title="Styles"],
.wyswigOuterWrapper [title="Link"],
.wyswigOuterWrapper [title="Clear formatting"] {
  display: none;
}
.mobilemargin {
  margin-left: $space-m;
}
.border {
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  padding-top: $space-xs;
  padding-left: $space-xs;
  word-wrap: break-word;
}
.minwidth {
  min-width: $space-xxxxl;
}
.mobileCC {
  display: flex;
}
.NONE {
  color: $green-600;
  margin-bottom: $space-xs;
}
.ccdisplay {
}

.DAMAGED {
  color: $red-600;
  margin-bottom: $space-xs;
}
.quantitycontrol {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Centers flex items vertically in the container */
  justify-content: center; /* Centers flex items horizontally in the container */
}
.quantitycontrol > * {
  margin: 0 8px; /* Adjust the 8px as needed */
}

.quantitycontrolMobile {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Centers flex items vertically in the container */
  justify-content: space-between; /* Centers flex items horizontally in the container */
  margin-top: $space-xxs;
}

.totalcart {
  display: flex;
  justify-content: space-between;
}
.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin-bottom: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cartItemDetails {
  margin-right: 16px;
}
/* Additional responsive adjustments */
@media (max-width: 600px) {
  .quantitycontrol {
    justify-content: space-between; /* Adjust spacing on smaller screens */
  }

  .cartItem {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: stretch; /* Stretch items to fill width */
  }
}

.selected,
.empty {
  color: $gray-700;
  margin-bottom: $space-xs;
}
.flexContainer {
  min-width: 210px;
  display: flex;
  align-items: flex-start; /* Correct vertical alignment to the top */
}

.iconContainer {
  /* If you need some spacing between the icon and the text */
  margin-right: 8px; /* Adjust the spacing as needed */
}

.textContainer {
  /* This will ensure the text is aligned center vertically, but it's mostly handled by flexContainer */
  /* Add any specific styling for the text here */
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.relativeWrapper {
  /* This class is for the common ancestor */
  position: relative;
}

.bottomsticky {
  position: absolute;
  bottom: 0;
  left: 0; /* Ensures it starts at the same point */
  right: 0; /* Stretches to the end of the parent */
  margin: 0 $space-m 0 $space-m;
  padding: $space-s;
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-100;
}

.cart-text {
  text-align: left;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  //cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top: $space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}

.labelActiveDamage,
.labelActiveNone,
.labelActive {
  background-color: $white;
}

.labelActiveNone {
  border-color: $green-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.labelActive {
  border-color: $orange-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.img {
  // margin-bottom: $space-xs;
  user-select: none;
}

.img img {
  width: 58px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding: 0 $space-xxs;
  position: relative;
  // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;

  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}

.sub_text {
  font-size: 0.857142857rem;
  line-height: 0.971428571;
  color: #777777;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: $space-xs;
  position: relative;
  text-align: center;
  @media screen and (max-width: $mobile) {
    padding: $space-xxs;
    font-size: 0.757142857rem;
    line-height: 0.801428571;
    //height:210px;
  }
}
.cartFrame {
}

.cartRemoveBtn {
  background-color: red;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.cartRemoveBtn:hover {
  background-color: darkred;
}

.cartHeader {
  margin-bottom: 10px;
}

.label input {
  //min-width: 55px;
}
.nospinners::-webkit-inner-spin-button,
.nospinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nospinners {
  -moz-appearance: textfield;
  width: 30px;
}
.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //justify-content: center;
}

.flexContainer > .flexRow {
  flex: 1 1 100%;
  /*grow | shrink | basis */
  height: auto;
}

.wizzardPickerWrapper > div:first-child {
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
