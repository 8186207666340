@import '../../styles/variables.scss';

// Styles go here
.holder{

   
    background-color: $gray-300;
    margin:$space-m;
}

[class*="bc"],
{
  color: black!important;
  -webkit-text-fill-color: black!important;
}
 
.inputWrap{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;

}
.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}
.wrapvert{
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;
  line-height: 1.67rem;
}
.margin{
  margin-left: $space-xs;
}
.icon {
  color: $green-600;
  margin-bottom: $space-xs;
}
.error {
  color: $red-600;
  margin-bottom: $space-xs;
  @media screen and (max-width: $mobile) {
  
    margin-bottom: 0;
    
  }
}
.body {
  max-width: 380px;
  padding-top: $space-xs;
  text-align: center;
}
.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile) {
    height: 120px;
    
    & img, & div {
      height: 100%!important;
      // max-width: 100%;
     }
  }
}

.img{
  text-align: center;
  //margin-top: 20px;
}
.wrap2 {
  display: flex;
 }


.labelActiveQr,.labelActiveManual,.labelActive {
  background-color: $white;
  
}

.labelActive{
  border-color: $orange-600 !important;
}

.img img {
  width: 58px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding:0 $space-xxs;
  position: relative;
 // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;
  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}

.lineHeight{
  line-height: 1.571428571;
}

.sub_text{
  font-size: 0.857142857rem;
  line-height: 0.971428571;
  color: #777777;
 // text-align: justify;
  padding: $space-s;
  position: relative;
 @media screen and (max-width: $mobile) {
  padding: $space-xxs;
  font-size: 0.757142857rem;
  line-height: 0.801428571;
  //height:210px;
}


}
.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
  
}
.flexContainer {
 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.flexContainer > .flexRow {
  flex: 1 1 100%; /*grow | shrink | basis */
  height: auto;
}


.wizzardPickerWrapper > div:first-child
{
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
.border{
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  padding-top: $space-xs;
  padding-left: $space-xs;
  word-wrap:break-word;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top:$space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}
