@import '../../styles/base.scss';

:global(body) {
  background-color: $gray-100;
}

div[class*='tagnewitem'] {
  color: red;
}

/* confirm styles */
button[class*='MuiButton-root'] {
  appearance: none;
  border-radius: $roundness-m;
  box-shadow: $depth-200;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;
  position: relative;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

button[class*='MuiButton-textPrimary'] {
  background-color: $gray-900 !important;
  border: 1px solid darken($gray-900, 10%);
  color: $white;
}

button[class*='MuiButton-textError'] {
  background-color: $white;
  color: $gray-900;
  border: 1px solid $gray-300;
  text-transform: none;
}

button[class*='MuiButton-root']:hover {
  background-color: initial;
}

p[class*='MuiDialogContentText-root'] {
  color: #242424;
}

div[class*='MuiDialogContent-root'] {
  padding-bottom: 12px;
}

div[class*='MuiDialog-paper'] {
  width: unset;
  max-width: 700px;
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
}

div[class*='MuiDialogActions-spacing'] {
  padding-bottom: 20px;
  padding-right: 20px;
}

button[class*='Mui-disabled'] {
  visibility: hidden;
}

button[class*='MuiAutocomplete-popupIndicator'] {
  display: none !important;
}

button[class*='MuiAutocomplete-clearIndicator'],
div[class*='MuiInputBase-root-MuiInput-root']::before {
  display: none !important;
}

div[class*='MuiInputBase-adornedEnd']::after,
div[class*='MuiInputBase-adornedEnd']::before {
  border-bottom: 0 !important;
}