$mobile: 600px;
$smallMobile: 374px;
$iphone: 380px;
$iphonePro: 414px;
$tablet: 720px;
$desktop-s: 960px;
$desktop-m: 1200px;
$desktop-l: 1500px;
$desktop-xl: 1920px;
$ipad:768px;
$ipadScale:650px;
$ipadHeight:1024px;