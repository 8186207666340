@import '../../styles/variables.scss';
@import '../../styles/shapes.scss';
a[href^="http://maps.google.com/maps"],a[href^="https://maps.google.com/maps"] {
  display: none !important;
}
.gmnoprint a,.gmnoprint span,.gm-style-cc { 
 display: none;
}
img[src="http://maps.gstatic.com/mapfiles/api-3/images/mapcnt3.png"] {
  display: none;
}
.gm-style-iw > button {
  display: none !important;
}
::ng-deep .gm-style .gm-style-iw-c button {
  display: none !important;
}
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw + div {display: none;}
.wrap {
  position: relative;
  height: 100%;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}
.loading {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.bottom{
  display: block;
  left: 10px;
  position: absolute;
  bottom: 10px;
  transform: translate3d(0, 0, 0);
  z-index: 2; 
  margin-right:$space-xxl;
}

.bottom button
{
  display: flex;
  margin-top: $space-xxxxs;
  width: 160px;
}

.hideButton {
  display: none;
  //left: $space-s;
  position: absolute;
  top: $space-xs;
  transform: translate3d(0, 0, 0);
  z-index: 2;
}

.menu {
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    display: none;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
      display: none;
     }
  @media screen and (max-width: $mobile) {
    display: block;
    left: 10px;
  }
}

.backButtonContainer {
  @media all and (min-width: ($mobile+1)) and (max-width: $desktop-s)  {
    display: none; // add !important to hide back button at 600px+ screen 
    left: 12px;
    top:55px;
    }
   
  @media screen and (max-width: $mobile) {
    display: none;
    left: 10px;
    top: 55px;
  }
}

.backButtonContainer button
{
  height: 40px;  
  font-size: 18px;
}

.backButtonContainer
{
  display: none;
}


.leftButton {
  display: block;
  left: $space-s;
  position: absolute;
  top: $space-xs;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  
}
.recenterButton {
  position: absolute;
  right: $space-xs;
  top: $space-xs;
  transform: translate3d(0, 0, 0);
  z-index: 2;
}

.map {
  float: top;
  height: 50%;
  width: 100%;
  
}
.mapFull {
  float: top;
  height: 100%;
  width: 100%;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  /* your css rules for ipad portrait */
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
   /* your css rules for ipad landscape */
  }
  @media screen and (max-width: $mobile) {
    margin-top: $space-xxxl;
    height: calc(100% - 64px);
  }
}

.mapNoGpsOverlay
{
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .65);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.mapNoGpsOverlayBanner
{
  z-index: 2;
  margin: auto;
  //background-color: rgba(255,255,255,0.75);
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
}

.mapNoGpsOverlayText
{
  margin: auto;
  font-size: 23px;
  font-weight: 500;
  line-height: 35px;
}

.coordinatesButtonContainer
{
  margin-top: 40px;
  text-align: center;
}

#map,#pano {
  float: left;
  height: 100%;
  width: 100%;
}

.pano{
  float: bottom;
  height: 50%;
  width: 100%;
  @media screen and (max-width: $mobile) {
    margin-top: 0; // margin-top: $space-xxxl;
    height:100%; //height: 90%;
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    /* your css rules for ipad portrait */
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
     /* your css rules for ipad landscape */
    }
}
.red{
  color:$red-600;
}
.map canvas:focus {
  outline: none;
}

:global {
  .azure-maps-control-button:focus {
    @include focus-dark;
  }

  .azure-maps-control-button.light {
    box-shadow: $depth-300;
  }

  .azure-maps-control-button.zoom-in {
    margin-bottom: 4px;

    &:after {
      display: none;
    }
  }

  .atlas-control-container {
    transform: translate3d(0, 0, 0);
  }

  .popup-content-container {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    white-space: normal;

    @include focus;
  }

  .popup-arrow,
  .popup-close {
    display: none;
  }

  // Popover
  .map-popover {
    background-color: $white;
    border-radius: $roundness-m;
    //box-shadow: $depth-300;
    padding-left: $space-xs;
    padding-top: $space-xxs;
    position: relative;
    pointer-events: none;
    width: 260px;

    &::after {
      bottom: -8px;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

      @include carret($white);
    }

    &__label {
      color: $gray-700;
      display: block;
      font-size: 0.857142857rem;
      padding-bottom: 2px;
    }

    &__name {
      color: $gray-900;
      font-size: 1.142857143rem;
      font-weight: 500;
      margin-bottom: $space-xxs;
    }

    &__status {
      font-size: 1rem;
      display: flex;
      align-items: center;
      margin-bottom: $space-xxs;
    }

    &__icon {
      display: inline-block;
      font-size: 22px !important;
      margin-right: $space-xxxs;

      &--OK::after {
        background-color: $green-600;
        border-radius: 3px;
        content: '';
        display: block;
        height: 6px;
        margin-right: 2px;
        width: 6px;
      }

      &--BATTERY_LOW,
      &--SIGNALSTRENGTH_LOW,
      &--ORIENTATION_CHANGE,
      &--TEMPERATURE_LOW {
        color: $orange-300 !important;
      }

      &--INACTIVE_EXPIRED_LICENSE {
        color: $gray-500 !important;
      }
    
      &--CONNECTIVITY_ISSUE,
      &--SIGNALSTRENGTH_CRITICAL,
      &--BATTERY_CRITICAL,
      &--COMMUNICATION_ERROR,
      &--TEMERATURE_HIGH {
        color: $orange-600 !important;
      }

      &--IMPACT {
        color: $red-600 !important;
      }
    }

    &__status {
      font-weight: 500;
    }

    &__updated {
      color: $gray-900;
      font-size: 1rem;
    }
  }
}

.simpleModeContainer
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 20vh 0;
}

.simpleModeContainer > .value
{
  font-weight: bold;
  font-size: 150%;
  text-align: center;
  max-width: unset;
}

.simpleModeContainer > button > span
{
  font-size: 165%;
  place-content: center;
}

.simpleModeContainer > button i 
{
  font-size: 150%!important;
}

.simpleModeContainer > *
{
  margin-bottom: $space-m;
}

.simpleModeContainer > button
{
  min-height: 15vh;
}
.disabled{
 
  user-select: none !important;
  opacity: 0.5 !important;
  pointer-events: none;
 
}
.disabledPointer{
  cursor: not-allowed !important;
 
}