@import '../../styles/variables.scss';

.breadcrumb {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  min-width: 0;
  user-select: none;
}
.red,
.title {
  height: 30px;
  min-width: 0;
  overflow: hidden;
  padding: $space-xxs $space-xxxs;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.red{
  color:red;
}
.breadcrumb:first-child {
  margin-left: -$space-xxxs;
}

.link {
  text-decoration: none;
}

.separator {
  position: relative;
  top: 2px;
}

:global(.breadcrumb) {
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

:global(.breadcrumb:last-child) {
  color: $gray-700;
  flex: 0 1 auto;

  @media screen and (max-width: $mobile) {
    display: block;
    color: $gray-900;
  }
}

:global(.breadcrumb:first-child:last-child) {
  color: $gray-900;
}

:global(.breadcrumb:last-child) .separator {
  display: none;
}

.deviceId
{
  font-size:16px!important;
  white-space: normal;
}

.tooltip
{
  font-size: 1rem!important;
  padding: 8px!important;
  line-height: 1.4;
  max-width: 300px;

  @media screen and (max-width: $mobile)
  {
    max-width: 70vw;
  }
}