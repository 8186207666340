@import '../../styles/variables.scss';

.termsOfService {
  background-color: $white;
  border-radius: $roundness-m;
  margin: $space-xl auto;
  max-width: 800px;
  padding: $space-l;

  h1 {
    text-align: center;
    margin-bottom: $space-m;
  }

  p {
    text-indent: $space-xl;
    margin-bottom: $space-m;
  }

  h2 {
    margin-bottom: $space-m;
  }

  ol {
    margin-bottom: $space-xl;
  }

 
  li {
    margin-bottom: $space-m;
    list-style-type: none;
  }

  li ol {
    margin: $space-m 0;
  }

  li ol li {
    margin-left: $space-l;
  }
}
.spacer{
  margin-left: $space-m !important;
}
