@import '../../styles/variables.scss';

.clearAlarm {
  //max-width: 475px;
  padding-bottom: $space-xl;
}

.wrapvert {
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;

}

.icon {
  color: $green-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.blue {
  color: $blue-300;
  margin-bottom: $space-xs;
  margin-right: $space-xs;
}

.error {
  color: $red-600;
  margin-bottom: $space-xs;
  margin-right: $space-xs;

  @media screen and (max-width: $mobile) {

    margin-bottom: 0;

  }
}

.wrap {
  display: flex;

  &>* {
    flex: 1;
  }
}

.spacer {
  padding-left: $space-xxxs;
  max-width: 25px
}

.alarmSelect {
  overflow-y: auto;

  &>* {
    padding-bottom: $space-xxs;

  }

}

.alarm {
  flex: 1;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;

  @media screen and (max-width: ($smallMobile)-25) {
    // padding: 0 $space-xxs;
  }

  position: relative;
  text-align: left;
  user-select: none;
  cursor:pointer;
  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }


  // Hide the focus state when the user is selecting
  &:active:focus {
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

}

.alarmSelected>div {
  border-radius: $roundness-m;
  border: 2px solid #ff7b00;
}

button {
  white-space: pre;
}

.button {
  flex: 1;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;
  position: relative;
  text-align: left;
  user-select: none;
  cursor: pointer;
  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }


  // Hide the focus state when the user is selecting
  &:active:focus {
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--icon {
    padding: 0 $space-xs;
  }
}

.center {
  //margin-top: 45%;
}

.row {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  min-height: 36px;
  padding: $space-xxxs;
  margin-right: $space-xs;
  word-break: break-word;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

}

.noteReview {
  line-height: 1.4;
}

.wyswigWrapper {
  overflow: auto;
  height: 35vh;
  line-height: 1.4;

  @supports not (-webkit-touch-callout: none) {

    /* CSS for other than iOS devices */
    @media screen and (max-width: $mobile) {
      height: 15vh;
    }

    @media screen and (max-width: $ipad) {
      height: 15vh;
    }
  }

  @media screen and (max-height: $ipad) {
    height: 15vh;
  }

  @media screen and (max-width: $ipad) {
    height: 15vh;
  }


  @supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    @media screen and (max-width: $mobile) {
      height: 15vh;
    }
  }
}

.wyswigWrapperSmall {
  height: 20vh;

  @media screen and (max-width: $mobile) {
    height: 15vh;
  }
}

.wyswigWrapper i,
.border i,
.wyswigWrapperSmall i {
  font-style: italic !important;
}

.wyswigOuterWrapper [title="Styles"],
.wyswigOuterWrapper [title="Link"],
.wyswigOuterWrapper [title="Clear formatting"] {
  display: none;
}

.border {
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  padding-top: $space-xs;
  padding-left: $space-xs;
  word-wrap: break-word;
}

.NONE {
  color: $green-600;
  margin-bottom: $space-xs;
}

.DAMAGED {
  color: $red-600;
  margin-bottom: $space-xs;
}

.INSPECTION,
.NOTE {
  color: $gray-700;
  margin-bottom: $space-xs;
}

.bodyLeft {

  padding-top: $space-xs;

}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top: $space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}

.labelActiveDamage,
.labelActiveNone,
.labelActive {
  background-color: $white;

}

.labelActiveNone {
  border-color: $green-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.labelActive {
  border-color: $orange-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.img {
  // margin-bottom: $space-xs;
  user-select: none;
}

.img img {
  width: 58px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding: 0 $space-xxs;
  position: relative;
  // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;

  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}

.sub_text {
  font-size: 0.857142857rem;
  line-height: 0.971428571;
  color: #777777;
  // text-align: justify;
  padding: $space-s;
  position: relative;

  @media screen and (max-width: $mobile) {
    padding: $space-xxs;
    font-size: 0.757142857rem;
    line-height: 0.801428571;
    //height:210px;
  }


}
.wrapcenter{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: $space-xl;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-top: $space-m;
  padding: $space-xs;
  }
.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;

}

.flexContainer {

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexContainer>.flexRow {
  flex: 1 1 100%;
  /*grow | shrink | basis */
  height: auto;
}


.wizzardPickerWrapper>div:first-child {
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}