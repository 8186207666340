@import "../../styles/variables.scss";

.summaryWrapper,
.event,
.resultsHeader {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

//summary
.summaryWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.summaryWrapper > * {
  margin: 0 $space-m 0 0;
}

.summaryWrapper > *:last-child {
  margin: 0;
}

.summaryWrapper > div {
  flex: 1;
}
.imageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
}
table,
.summaryWrapper {
  width: 100%;
}

td {
  vertical-align: middle;
}

th {
  border-bottom: 2px #ffbd43 solid !important;
}

.tableTitle {
  padding: 10px;
}

.tableInnerWrapper {
  padding: 3px 10px 3px 10px;
}

.tableTitle {
  font-weight: 500;
  border-bottom: 1px $gray-300 solid;
}

.tableOuterWrapper {
  border: 1px $gray-300 solid;
  border-radius: 3px;
  background-color: $white;
}

.titleHeader {
  flex-basis: 100%;
  margin: 0 0 $space-m 0 !important;
}

.accordionWrapper > div {
  background-color: #ffc25096;
}

.accordionHeader {
  width: 100%;
}

.accordionHeader > div {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.accordionHeader > div:last-child {
  float: right;
}

.accordionHeader i {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.accordionHeader div:first-child i {
  margin-right: 8px;
}

.accordionHeader div:last-child i {
  margin-left: 8px;
}

.accordionHeader div:last-child span::after {
  content: "view";
}
.spacer {
  display: inline-flex;
  margin-right: $space-xxxs;
}
.infoText {
  color: black;
}

.whiteText {
  color: black;
}
//
.inspectionEdit {
  float: right;
  margin-left: auto;
}
.wrap {
  align-items: center;
  // display: inline-block;
  justify-content: center;
}
.inspectionEdit {
  text-align: right;
  flex-basis: 100% !important;
  margin: 12px 0 0 0 !important;
}

.eventWrapper {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.eventWrapper > * {
  flex: 1;
  margin: 0 $space-m 0 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}

.resultsHeader {
  margin-top: $space-m;
  margin-bottom: $space-s;
  border-bottom: $gray-300 1px solid;
  padding-bottom: $space-xs;
  font-size: 1.142857143rem;
  font-weight: 500;

  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: space-between;
  }
}

.resultsHeader > span {
  height: 36px;
  line-height: 36px;
}

.selectTimeRangeWrapper {
  max-width: 370px;
}

//photo
.photoOuterWrapper {
  flex: 0 0 50px;
  margin-right: 8px;
  height: 50px;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: $depth-200;
  margin-bottom: 8px;
}

.photoWrapper > div > div > img {
  max-height: 100% !important;
  max-width: 100% !important;
}
//

//eventHeader
.eventHeader {
  display: flex;
}

.eventHeader > * {
  line-height: 24px;
  height: 24px;
  margin-bottom: 24px;
}

.eventHeader i {
  align-items: center;
  display: flex;
  padding-right: 4px;
}

.eventHeader span {
  font-size: 1.142857143rem;
  color: $black;
}
//

.inspectionItemButton > button {
  opacity: 0;
}

.controls {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-basis: 100%;
}

.controls > * {
  margin: 0 $space-m 0 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.selectTimeRangeWrapper span {
  padding-bottom: 0;
}

.selectEventsWrapper {
  display: flex;
  justify-content: space-between;
}

.selectEventsWrapper > *:last-child > span {
  margin-bottom: 8px;
}

.controls > *:last-child > span {
  margin-bottom: 8px;
}

.buttons > *:last-child {
  //margin-left: $space-s;
}

.selectTimeRangeWrapper {
  margin-right: 12px !important;
}

.selectEventsWrapper {
  border-left: 1px $gray-200 solid;
  border-right: 1px $gray-200 solid;
  padding: 0 12px 0 12px;
  margin-right: 12px;
}

.selectTimeRangeWrapper label {
  margin-right: 8px;
}

.dayPickerWrapper > * {
  z-index: 10 !important;
}

.dayPickerGroup * {
  z-index: 2;
}

.dayPickerGroup {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.dateRangeSpacer {
  margin: 0 8px;
}

.controls {
  margin: 0;
}

.noteBody {
  line-height: 1.4;
}

.dayPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 30px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;
  text-align: center;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
.spacer {
  margin-bottom: $space-xs;
}
//tables
table {
  padding: $space-m !important;
}

tr:hover {
  background-color: $gray-100;
}

tr:first-child:hover {
  background-color: initial;
}

td,
th {
  padding: 10px 0 10px 0 !important;
}

td a {
  display: block;
}

tr:last-child {
  border-bottom: 0 !important;
}

tr {
  border-bottom: $gray-300 1px solid !important;
}

tr.emptyRow {
  border: 0 !important;
}

tr.emptyRow:hover {
  background-color: initial;
}

td:last-child {
  text-align: right;
  padding-left: 24px !important;
  vertical-align: middle;
}

th > div {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

//inspection
.inspection > div {
  flex-basis: 12, 5%;
  padding-right: $space-s;
}

.inspection > div:nth-child(4n + 4) {
  //padding-right: 0;
  //flex-basis: 6%;
}

.inspection > div:nth-child(n + 10) {
  margin-top: $space-xl;
}

.inspectionItemNotes {
  flex-basis: 100% !important;
}

.inspection {
  background-color: $white;
  padding: $space-m;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  // border: 1px $gray-700 solid;
}

.event {
  margin-bottom: $space-xxs;
}

///
.empty {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: $space-s $space-m;
}
.name {
  margin-bottom: $space-m;
}
.title {
  color: $gray-800;
}

.meta {
  display: flex;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.metaItem {
  flex: 1;

  @media screen and (max-width: $mobile) {
    &:first-child {
      margin-bottom: $space-m;
    }
  }
}
.container {
  display: flex;
  //height: 100%;

  outline: none;
}

.left,
.right {
  height: 100%;
}

.left {
  flex: 1;
}
.red {
  color: $red-600;
}
.right {
  flex: 8;
}

.toRight {
  display: flex;
  justify-content: flex-end;
}
.text {
  max-width: $mobile;
  padding: 0 $space-m;
}

.icon {
  color: $blue-300;
}

.response {
  background-color: $gray-200;
  border: 1px solid $gray-300;
  font-family: monospace;
  line-height: 1.4;
  margin-top: $space-xxs;
  padding: $space-s;
}

.frame {
  border-radius: $roundness-m;
  box-shadow: $depth-100;
  padding: $space-m $space-m 16px $space-m;
  position: relative;
  width: 100%;
  background-color: $gray-100;
}

@media screen and (min-width: $ipad) and (max-width: $ipad) and (orientation: landscape),
  screen and (min-width: $ipadHeight) and (max-width: 1100px) {
  .summaryWrapper > *:last-child {
    flex: 0.6 1;
  }

  td:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }

  td:first-child a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
  }

  // picker position

  .eventWrapper > *:last-child {
    flex: 0 0 75px;
  }

  .eventWrapper > *:nth-child(3) div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }

  .inspection {
    justify-content: flex-start;
  }

  .inspection > div {
    flex-basis: 25%;
    padding-right: $space-s;
  }

  .inspection > div:nth-child(n + 5) {
    margin-top: $space-xl;
  }

  .inspectionEdit {
    padding: 0 !important;
  }

  .inspectionItemButton {
    position: absolute;
    right: 40px;
    top: 96px;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (min-width: $ipad) and (max-width: $ipad) and (orientation: portrait),
  screen and (min-width: ($mobile+1)) and (max-width: 1023px),
  screen and (min-width: $ipadHeight) and (max-width: $ipadHeight) and (min-height: 1366px) and (max-height: 1366px) and (orientation: portrait) {
  .inspection > div {
    flex-basis: 33%;
  }

  .inspection {
    justify-content: flex-start;
  }

  .inspection > div:nth-child(n + 4) {
    margin-top: $space-xl;
  }

  .summaryWrapper {
    flex-basis: 100%;
  }

  .summaryWrapper > *:first-child {
    flex-basis: 100%;
    margin: 0 0 24px 0;
  }

  td:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }

  .eventWrapper > * {
    margin: 0 24px 12px 0;
  }

  .eventWrapper > *:nth-child(2) {
    flex-basis: 400px;
    margin: 0 24px 24px 0;
  }

  .eventWrapper > *:nth-child(3) {
    margin-left: 74px;
    flex-basis: 242px;
  }

  .eventWrapper {
    flex-wrap: wrap;
  }

  .inspectionEdit {
    padding: 0 !important;
  }

  .inspectionItemButton {
    position: absolute;
    right: 40px;
    top: 96px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .inspectionItemButton > button > span > div,
  .inspectionItemButton > button > span > span {
    display: none;
  }
}

@media screen and (max-width: $mobile) {
  .controls > *:nth-child(1) {
    margin: 0 6px 0 0 !important;
  }

  .controls > *:nth-child(2) {
    padding: 0 6px 0 6px;
    margin: 0 6px 0 0;
  }
  .emptyItem {
    display: none;
  }
  .controls > *:nth-child(2) div button {
    margin-bottom: 0;
  }

  .controls > *:nth-child(2) div button {
    padding: 0 4px 0 2px;
  }

  .controls > *:nth-child(2) .buttons {
    margin-bottom: 0;
  }

  .controls > *:last-child > button {
    padding: 0 12px;
  }

  .summaryWrapper {
    flex-basis: 100%;
  }

  .summaryWrapper > div {
    flex-basis: 100%;
    margin: 0 0 24px 0;
  }

  .summaryWrapper > div:last-child {
    margin: 0;
  }

  .eventWrapper > * {
    margin: 0 0 24px 0;
  }

  .inspection > div {
    flex-basis: 100%;
    padding-right: 0;
    margin: 0 0 30px 0;
  }

  .inspection > div:last-child {
    margin: 0;
  }

  .accordionHeader > div:last-child {
    display: none;
  }

  .inspectionItemNotes {
    margin-top: 0 !important;
  }
}

//pagination
.paginationContainer {
  display: flex;
  padding-left: 0 !important;
  justify-content: center;
}

.paginationContainer li {
  list-style: none;
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  text-align: center;
  user-select: none;
  cursor: pointer;
  margin: 4px;
  line-height: 36px;
  @media screen and (max-width: $mobile) {
    margin: 3px;
  }
}

.paginationContainer a {
  padding: 24px;
  @media screen and (max-width: $mobile) {
    padding: 12px;
  }
}

.paginationContainer a:focus {
  box-shadow: none;
}

.paginationContainer > *:first-child {
  margin-left: 0;
}

.paginationContainer li.paginationButonDisabled,
.paginationContainerHidden {
  display: none;
}

.paginationContainer li.paginationButonActive {
  cursor: default !important;
  background-color: #f8f8f8;
  box-shadow: none;
  opacity: 0.65;
}

.paginationContainer li[class="previous"] {
  margin-right: 32px;
  @media screen and (max-width: $mobile) {
    margin-right: 12px;
  }
}

.paginationContainer li[class="next"] {
  margin-left: 32px;
  @media screen and (max-width: $mobile) {
    margin-left: 12px;
  }
}

.paginationContainer li[class="break"] {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.paginationContainer li[class="break"] a {
  padding: 0;
  position: relative;
  top: 10px;
  font-weight: normal;
}

.paginationActive {
  font-weight: bold;
}

.resultsHeader .resultsPerPageContainer > div // select - results per page
{
  width: auto;
  display: inline-block;
}

.resultsHeader .resultsPerPageContainer > div select {
  padding: 0 18px 0px 13px;
}

.resultsHeader .resultsPerPageContainer div[class*="carret"] {
  right: 6px;
}

.exportPopoverButtonsContainer button {
  width: 100%;
}

.resultsHeader div[class*="Popover_popover"] {
  left: -190px !important;
}

.resultsHeader > div:last-child {
  float: right;
}

@media screen and (max-width: 639px) {
  .resultsHeader > div:last-child {
    display: flex;
  }

  .resultsHeader > div:last-child > button {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .resultsHeader button[class*="Popover_target"] {
    margin-bottom: 0;
  }
  .resultsHeader > *:first-child {
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    margin-right: 3px;
  }
  .resultsHeader > div:last-child {
    flex-grow: 2;
    display: block;
  }
}

.line {
  border-left: $gray-500 1px solid;
  height: 20px;
  margin: auto 0 auto 0;

  @media screen and (min-width: 500px) {
    margin-left: 3px;
    padding-right: 3px;
    border-width: 2px;
  }
}
