@import "../../styles/variables.scss";

button {
  white-space: pre;
}

.selected {
  border: 1px solid !important;
  border-color: $orange-600 !important;
  box-shadow: 0 1px 4px 0 rgba($orange-600, 0.32) !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.button {
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer !important;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 $space-m;
  position: relative;
  text-align: center;
  user-select: none;
  cursor: pointer;
  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }

  :global(a:visited) & {
    border-color: $gray-300;
  }

  // Hide the focus state when the user is selecting
  &:active:focus {
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--icon {
    padding: 0 $space-xs;
  }
}

.content {
  align-items: center;
  display: flex;
}

.centerNoFlex {
  align-items: center;
  // display: flex;
}

.spacer {
  padding-left: $space-xxs;
}

.transparent {
  font-weight: 400;
  //min-width: 200px;
  cursor: pointer !important;
  padding: 0 $space-xxs;
  appearance: none;
  background-color: $white;
  border-radius: 0;
  border: 0;
  width: auto;
  height: 36px;
  line-height: 34px;
  //padding: 0 $space-xs;
  position: relative;
  text-align: center;
  user-select: none;

  @include focus;

  &:not([disabled]):active {
    color: $gray-700;
  }

  :global(a:visited) & {
    //border-color: $gray-300;
    background-color: $white;
    border-radius: 0;
    box-shadow: none;
  }

  // Hide the focus state when the user is selecting
  &:active:focus {
    background-color: $white;
    border-radius: 0;
    box-shadow: none;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--icon {
    padding: 0 $space-xs;
  }
}

.primary {
  background-color: $gray-900;
  border: 1px solid darken($gray-900, 10%);
  color: $white;

  &:not([disabled]):active {
    color: $gray-300;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-900;
    opacity: 0.7;
  }

  :global(a:visited) & {
    border-color: darken($gray-900, 10%);
  }
}

.orange {
  background-color: $brand;
  border: 1px solid darken($brand, 10%);
  color: $white;

  &:not([disabled]):active {
    color: $gray-300;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-900;
    opacity: 0.7;
  }

  :global(a:visited) & {
    border-color: darken($gray-900, 10%);
  }
}

.danger {
  color: $red-800;
}

/** Sizes */
.xs {
  font-weight: 400;
  height: 21px;
  line-height: 20px;
  padding: 0 $space-xxxs;
}
.s {
  font-weight: 400;
  height: 26px;
  line-height: 24px;
  padding: 0 $space-xs;
}
.mobile {
  font-weight: 400;
  height: 26px;
  line-height: 24px;
  padding: 0 $space-xs;
  font-size: smaller;
}

.xl {
  font-weight: 400;
  height: 76px;
  line-height: 74px;
  padding: 0 $space-xl;
}

/** Loading state */
.isLoading {
  opacity: 0;
}

.loadingSpinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
