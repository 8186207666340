@import '../../styles/variables.scss';

.frameWrap {

  //min-width: 560px;
  max-width: 780px;
  text-align: center;
  width: 100%;
  margin-top:48px;
  align-self: flex-start;

  @media screen and (max-height: 1000px) {
    align-self: flex-start;
    margin: $space-xl 0;
  }

  @media screen and (max-width: 780) {
    max-width: 100%;
    margin: $space-m;
  }
}
.frame{
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-100;
  padding: $space-m;
  position: relative;
  width:100%;
  min-height: 815px;
}
.logoWrap {
  padding: $space-xxs;// $space-m $space-xs $space-xl;
}

.heading {
  margin-bottom: $space-xxs;
}

.createAccount {
  color: $gray-700;

  a {
    color: $gray-900;
    display: inline-block;
    margin-top: $space-xxs;
  }
}
