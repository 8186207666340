@import "../../styles/variables.scss";

.break {
  height: 1px;
  width: 100%;
  background: $gray-300;
  margin-top: $space-xs;
  margin-bottom: $space-xs;
}

.deleteWrap {
  text-align: center;
  margin: $space-l 0;
}

.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}

.cartFrame {
}
.quantitycontrol {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Centers flex items vertically in the container */
  justify-content: center; /* Centers flex items horizontally in the container */
}
.quantitycontrol > * {
  margin: 0 8px; /* Adjust the 8px as needed */
}

.quantitycontrolMobile {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Centers flex items vertically in the container */
  justify-content: space-between; /* Centers flex items horizontally in the container */
  margin-top: $space-xxs;
}

.totalcart {
  display: flex;
  justify-content: center;
}
.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin-bottom: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cartItemDetails {
  margin-right: 16px;
}
/* Additional responsive adjustments */
@media (max-width: 600px) {
  .quantitycontrol {
    justify-content: space-between; /* Adjust spacing on smaller screens */
  }

  .cartItem {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: stretch; /* Stretch items to fill width */
  }
}
.cartRemoveBtn {
  background-color: red;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.cartRemoveBtn:hover {
  background-color: darkred;
}

.cartHeader {
  margin-bottom: 10px;
}
.rowItem {
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100vh; /* Takes full viewport height */
}

.rowItem_right {
  align-items: right;

  //height: 100vh; /* Takes full viewport height */
}
.label input {
  //min-width: 55px;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.relativeWrapper {
  /* This class is for the common ancestor */
  position: relative;
}
.container {
  position: absolute;
  bottom: 0;
  width: 93%;
}
.header {
  position: sticky;
  top: 0;
  color: $gray-700;
  font-size: 1rem;
  margin-bottom: $space-s;
}
.bottomsticky {
  position: sticky;
  bottom: 0;
  left: 0; /* Ensures it starts at the same point */
  right: 0; /* Stretches to the end of the parent */
  //margin: 0 $space-xxs 0 $space-xxs;
  padding: $space-s;
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-100;
}

.cart-text {
  text-align: left;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
}

.icon {
  color: $green-600;
  margin-bottom: $space-xs;
}

.rowFull {
  width: 100%;
  padding: $space-xs;
}
.whiteText {
  color: black;
}

.accordionHeader {
  width: 100%;
}

.accordionHeader > div {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.accordionHeader > div:last-child {
  float: right;
}

.accordionHeader i {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.accordionHeader div:first-child i {
  margin-right: 8px;
}

.accordionHeader div:last-child i {
  margin-left: 8px;
}

.accordionHeader div:last-child span::after {
  content: "view";
}
.span_price {
  min-width: 100px;
  text-align: right;
}
.span_small {
  min-width: 100px;
  text-align: center;
}
.spacer {
  display: inline-flex;
  margin-right: $space-xxxs;
}

.infoText {
  color: black;
}

.whiteText {
  height: 100%;
  color: black;
}

//

.wrap {
  align-items: center;
  // display: inline-block;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center; /* Centers items vertically in row layout */

  @media screen and (max-width: $mobile) {
    // flex-direction: column;
  }
}
.new {
  color: $brand;
}
.expired,
.red {
  color: red;
}

.filterMenu {
  //justify-content: end!important;
  background: white !important;
  flex-direction: column;
}

.row > * {
  display: flex; /* Makes each item a flex container */
  align-items: center; /* Centers the content of each item vertically */
  flex: 0.5;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}
.verticalButtons {
  display: flex;
  flex-direction: column;
}
.minHeight {
}

.managerowbigger > * {
  flex: 0.5;
  min-height: 48px;
  &:last-child {
    margin-right: 0;
  }
}

.managerow > * {
  flex: 0.5;

  &:last-child {
    margin-right: 0;
  }
}
.expiringsoon {
  background-color: $yellow !important;
}

.popover div {
  display: block !important;
}

.row div {
  height: 23px;
  align-items: center;

  justify-content: left;
  @media screen and (max-width: $mobile) {
    margin: 3px 0;
  }
}

.eventWrapper {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}
.licensefield {
  width: 40px; /* Make the input field smaller */
  border-radius: 5px; /* Make the input field rounded */
  -webkit-appearance: none; /* Remove the controls for Chrome, Safari, and Opera */
  -moz-appearance: textfield; /* Remove the controls for Firefox */
  //center text inside
  text-align: center;
}
.managelicense {
  background-color: $white;
  //padding: $space-xxs;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center items horizontally
  align-items: center; // Center items vertically
  width: 100%;
  border-radius: 4px;
  text-align: center; // Center text
}

.managelicense > div {
  width: 100%;
  border-radius: 4px;
  padding: $space-xxs;
  display: flex;
  justify-content: center; // Center items horizontally
  align-items: center; // Center items vertically
  text-align: center; // Center text

  @media screen and (max-width: $mobile) {
    // flex-direction: column;
  }
}

.managelicense > div:nth-child(odd) {
  background: #f8f8f8;
}
.license > div {
  width: 100%;
  padding: $space-xxs;
  display: flex;
  justify-content: left;
  align-items: flex-start; /* Ensures items are aligned to the start */
  border-radius: 4px;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    text-align: left;
  }
}

.license > div:nth-child(odd) {
  background: #f8f8f8;
}

.license {
  background-color: $white;
  padding: $space-xxs;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
}
.sub_top {
  background-color: $white;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
}
.sub_top > div {
  width: 100%;

  display: flex;
  justify-content: left;
}

.selected,
.empty {
  color: $gray-700;
  margin-bottom: $space-xs;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  //padding: $space-xs 0;
  padding-top: $space-xs;
  padding-bottom: $space-xxxs;
  //justify-content: center;
  flex: 1;

  @media screen and (max-width: $smallMobile) {
    margin-right: ($space-xs)-2;
  }

  &:first-child {
    @media screen and (max-width: $smallMobile) {
      margin-left: -4px;
    }
  }

  &:last-child {
    margin-right: 0;

    @media screen and (max-width: $smallMobile) {
      margin-right: -4px;
    }
  }
}

.labelActiveDamage,
.labelActiveNone,
.labelActive {
  background-color: $white;
}

.labelActiveNone {
  border-color: $green-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.labelActive {
  border-color: $orange-600;
}

.labelActiveDamage {
  border-color: $red-600;
}

.img {
  // margin-bottom: $space-xs;
  user-select: none;
}

.img img {
  width: 58px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.171428571;
  user-select: none;
  text-align: center;
  justify-content: center;
  padding: 0 $space-xxs;
  position: relative;
  // height:80px;
  height: 35px;
  max-height: 40px;
  margin-bottom: $space-xxxs;

  //height:21px;
  @media screen and (max-width: $mobile) {
    font-size: 1.017142857rem;
    line-height: 1.171428571;
    margin-bottom: $space-m;
    height: 40px;
    max-height: 45px;
  }

  @media screen and (max-width: $smallMobile) {
    max-height: 50px;
    height: 55px;
  }
}

.center {
  //margin-top: 45%;
}

.sub_text {
  font-size: 0.857142857rem;
  line-height: 0.971428571;
  color: #777777;
  // text-align: justify;
  padding: $space-s;
  position: relative;

  @media screen and (max-width: $mobile) {
    padding: $space-xxs;
    font-size: 0.757142857rem;
    line-height: 0.801428571;
    //height:210px;
  }
}

.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexContainer > .flexRow {
  flex: 1 1 100%;
  /*grow | shrink | basis */
  height: auto;
}

.wizzardPickerWrapper > div:first-child {
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}

.wrap2 {
  display: flex;
  height: 100%;
  justify-content: center;
}

.wrap2 {
  display: flex;

  & > * {
    flex: 1;
  }
}
