@import '../../styles/variables.scss';

.Mui-expanded button
{
  opacity: 1;
  transition: visibility 0.5s, opacity 0.3s linear;
}

.Mui-expanded .accordionHeader i 
{
  transform: rotate(270deg);
  transition: transform 0.2s ease-in-out; 
}

.Mui-expanded .accordionHeader div:last-child span::after
{
 content: "hide";
}

.rs-calendar-table-cell-content:hover
{
  background-color: #ff7b00!important;
}

div:focus {

  outline-width: 0!important;
  outline: none!important;
}

.rs-picker-toggle 
{
    color:#242424!important;
    border-radius: $roundness-m!important;
    border: 1px solid $gray-300!important;
    box-shadow: $depth-200;
    height: 38px!important;
    line-height: 22px!important;
  }

  .rs-picker-toggle-placeholder, .rs-picker-toggle-caret
  {
    color: #242424!important;
    font-weight: 500;
  }

@media screen and (max-width: $mobile)
{
  .MuiAccordionSummary-root
  {
    padding: 0 8px!important;
  }
}

@media screen and (min-height:720px) and (max-height:810px) 
{
  .rs-picker-daterange-header
{
  display: none;
}

}