@import '../../styles/variables.scss';

.avatar {
  align-items: center;
  border-radius: 17px;
  color: $white;
  display: flex;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  text-transform: uppercase;
  width: 34px;
}

.default {
  background-color: $blue-300;
}

.pending {
  background-color: $gray-500;
}
