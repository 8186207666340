@import '../../styles/variables.scss';

.deleteWrap {
  text-align: center;
  margin: $space-l 0;
}

.buttons {
  &>* {
    margin-left: $space-xxs;
  }
}

.break {
  height: 1px;
  width: 100%;
  background: $gray-300;
  margin: $space-xs 0 $space-l;

}