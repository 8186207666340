@import '../../styles/variables.scss';

.filter {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.spacer{
  margin-bottom:$space-xs;
}
.status {
  background-color: $gray-300;
  border-radius: 3px;
  height: 7px;
  margin-top: 6px;
  width: 7px;

  &.ok {
    background-color: $green-600;
  }

  &.warning {
    background-color: $orange-300;
  }

  &.critical {
    background-color: $orange-600;
  }

  &.impacted {
    background-color: $red-600;
  }
  &.inprogress {
    background-color: $gray-700;
  }
  &.inactive {
    background-color: $gray-700;
  }
  &.ready {
    background-color: $blue-300;
  }
  &.expired {
    background-color: $gray-700;
  }
}
